// extracted by mini-css-extract-plugin
export var avatar = "style-module--avatar--6f13a";
export var avatarLoading = "style-module--avatarLoading--771a7";
export var avatarLoadingActive = "style-module--avatarLoadingActive--06ed4";
export var cardano = "style-module--cardano--0ceee";
export var extra = "style-module--extra--2a15b";
export var header = "style-module--header--60256";
export var link = "style-module--link--a0530";
export var linkActive = "style-module--linkActive--a49d4";
export var links = "style-module--links--308e2";
export var linksMobile = "style-module--linksMobile--d7cf0";
export var logo = "style-module--logo--3430f";
export var logoNetwork = "style-module--logoNetwork--9c31e";
export var menu = "style-module--menu--79626";
export var more = "style-module--more--a5d19";
export var offline = "style-module--offline--9472a";
export var settings = "style-module--settings--0eaf7";
export var socials = "style-module--socials--3281c";