import { CachedTokensTypes } from "./"

export function CLEAR(): CachedTokensTypes.AClear {
  return { type: CachedTokensTypes.Enum.CLEAR }
}

export function CACHED_TOKENS_ADD(cachedTokens: CachedTokensTypes.ICachedTokens): CachedTokensTypes.ACachedTokensAdd {
  return { type: CachedTokensTypes.Enum.CACHED_TOKENS_ADD, cachedTokens }
}
export function CACHED_TOKENS_REMOVE(cachedTokens: string[]): CachedTokensTypes.ACachedTokensRemove {
  return { type: CachedTokensTypes.Enum.CACHED_TOKENS_REMOVE, cachedTokens }
}
export function CACHED_TOKENS_UPDATE_SAGA(
  tokensToAdd: CachedTokensTypes.ITokenRaw[]
): CachedTokensTypes.ACachedTokensUpdateSaga {
  return { type: CachedTokensTypes.Enum.CACHED_TOKENS_UPDATE_SAGA, tokensToAdd }
}
