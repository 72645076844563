import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"
import { AccountActions, AccountTypes } from "./"
import { message } from "antd"
import { getConnectorsWithStatus } from "@/services/connectors"
import config from "@/config/common"
import Kupo, { KupoTypes } from "@/services/kupo-ts-client"
import BigNumber from "bignumber.js"
import AssetFingerprint from "@emurgo/cip14-js"

export function* ACCOUNT_ADD_SAGA({ account }: AccountTypes.AAccountAddSaga) {
  const accountsList: AccountTypes.Account[] = yield select((state: RootState) => state.account.accountsList)
  const alreadyAdded = accountsList.filter(
    (i) => i.stakeKey === account.stakeKey && i.connectorId === account.connectorId
  )[0]
  if (alreadyAdded) {
    message.info("Wallet already added")
    yield put(AccountActions.ACCOUNT_CURRENT_SET(alreadyAdded))
    return
  }
  yield put(AccountActions.ACCOUNTS_LIST_SET([...accountsList, account]))
  yield put(AccountActions.ACCOUNT_CURRENT_SET(account))
  message.success("Wallet connected")
}

export function* ACCOUNT_REMOVE_SAGA({ account }: AccountTypes.AAccountRemoveSaga) {
  const accountsList: AccountTypes.Account[] = yield select((state: RootState) => state.account.accountsList)
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
  const removeIndex = accountsList.map((account) => account.id).indexOf(account.id)
  const updatedWalletsList = [...accountsList]
  updatedWalletsList.splice(removeIndex, 1)
  yield put(AccountActions.ACCOUNTS_LIST_SET(updatedWalletsList))
  if (account?.id === accountCurrent?.id) {
    yield put(AccountActions.ACCOUNT_CURRENT_SET(updatedWalletsList[0]))
  }
  if (!updatedWalletsList.length) {
    yield put(AccountActions.ACCOUNT_CURRENT_SET(null))
  }
  message.error("Wallet removed")
}

export function* ACCOUNT_INFO_UPDATE_SAGA({ loadingType, clear }: AccountTypes.AAccountInfoUpdateSaga) {
  if (clear) yield put(AccountActions.ACCOUNT_INFO_CLEAR())
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
  if (!accountCurrent) return

  const abortController = new AbortController()
  try {
    yield put(AccountActions.ACCOUNT_INFO_REQUEST(loadingType))
    const dataAccountInformation: {
      success?: AxiosResponse<KoiosTypes.AccountInfoResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountInfo,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )

    if (dataAccountInformation?.success) {
      const __dataAccountInformation = dataAccountInformation?.success?.data?.[0] || null
      const accountInfo: AccountTypes.AccountInfo = yield select((state: RootState) => state.account.accountInfo)
      if (accountInfo?.total_balance !== __dataAccountInformation?.total_balance) {
        yield put(AccountActions.BALANCE_UPDATE_SAGA())
      }
      if (
        __dataAccountInformation?.delegated_pool &&
        (accountInfo?.delegated_pool !== __dataAccountInformation?.delegated_pool || loadingType === "default")
      ) {
        const dataPoolInformation: {
          success?: AxiosResponse<KoiosTypes.PoolInfoResponse>
          error?: AxiosError
        } = yield call(
          Koios.PoolInfo,
          { _pool_bech32_ids: [__dataAccountInformation?.delegated_pool] },
          undefined,
          undefined,
          abortController.signal
        )
        if (dataPoolInformation?.success) {
          const _dataPoolInformation = dataPoolInformation?.success?.data?.[0]
          yield put(AccountActions.ACCOUNT_INFO_SUCCESS(__dataAccountInformation, _dataPoolInformation))
        }
        if (dataPoolInformation?.error) {
          yield console.log("dataPoolInformation :: error")
        }
      } else {
        yield put(AccountActions.ACCOUNT_INFO_SUCCESS(__dataAccountInformation))
      }
    }
    if (dataAccountInformation?.error) {
      yield console.log("dataAccountInformation :: error")
      yield put(AccountActions.ACCOUNT_INFO_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(AccountActions.ACCOUNT_INFO_FAILURE())
    }
  }
}

export function* BALANCE_UPDATE_SAGA({ clear }: AccountTypes.ABalanceUpdateSaga) {
  if (clear) yield put(AccountActions.BALANCE_CLEAR())
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
  if (!accountCurrent) return

  const abortController = new AbortController()
  try {
    yield put(AccountActions.BALANCE_REQUEST())
    const dataUtxosRaw: {
      success?: AxiosResponse<KupoTypes.UtxosResponse>
      error?: AxiosError
    } = yield call(
      Kupo.utxosByStakeKey,
      {
        _stake_key: accountCurrent.stakeKey,
      },
      "?unspent",
      undefined,
      abortController.signal
    )
    if (dataUtxosRaw?.success) {
      const format = /^([/\\\[\]*<>(),.!?@+=%&'"|a-zA-Z0-9 _-]+)$/
      const assetFromUtxoId = (assetId: string) => {
        const [policyId, assetName] = assetId.split(".")
        const assetNameAscii = Buffer.from(assetName || "", "hex").toString("utf-8") || ""
        const fingerprint = AssetFingerprint.fromParts(Buffer.from(policyId, "hex"), Buffer.from(assetName, "hex"))
        return {
          assetId,
          assetName,
          assetNameAscii: format.test(assetNameAscii) ? assetNameAscii : assetName,
          fingerprint: fingerprint.fingerprint(),
          policyId,
        }
      }
      const getSummaryFromUxos = (utxos: KupoTypes.IUtxo[]) => {
        const addresses: { [key: string]: boolean } = {}
        const assetsSummary: { value: string; tokens: { [assetId: string]: AccountTypes.BalanceToken } } = {
          value: "0",
          tokens: {},
        }
        utxos.forEach((utxo) => {
          addresses[utxo.address] = true
          assetsSummary.value = new BigNumber(assetsSummary.value).plus(utxo.value.coins).toString()
          const assets = utxo.value.assets
          if (Object.keys(assets).length) {
            Object.entries(assets).forEach(([assetId, quantity]) => {
              if (!assetsSummary.tokens[assetId]) {
                assetsSummary.tokens[assetId] = {
                  quantity: "0",
                  asset: {
                    assetId: "",
                    assetName: null,
                    assetNameAscii: "",
                    fingerprint: "",
                    policyId: "",
                  },
                }
              }
              assetsSummary.tokens[assetId] = {
                quantity: new BigNumber(assetsSummary.tokens[assetId].quantity).plus(quantity).toString(),
                asset: assetFromUtxoId(assetId),
              }
            })
          }
        })
        const __utxos: AccountTypes.BalanceUtxo[] = utxos.map((utxo) => {
          return {
            txHash: utxo.transaction_id,
            txIndex: utxo.output_index,
            address: utxo.address,
            value: utxo.value.coins.toString(),
            datumHash: utxo.datum_hash,
            datumType: utxo.datum_type,
            scriptHash: utxo.script_hash,
            tokens: Object.keys(utxo.value.assets).map((assetId) => {
              return {
                quantity: utxo.value.assets[assetId].toString(),
                asset: assetFromUtxoId(assetId),
              }
            }),
          }
        })
        return {
          value: assetsSummary.value,
          tokens: Object.keys(assetsSummary.tokens).map((key) => assetsSummary.tokens[key]),
          addresses: Object.keys(addresses),
          utxos: __utxos,
        }
      }
      const summary = getSummaryFromUxos(dataUtxosRaw?.success.data)
      yield put(AccountActions.BALANCE_SUCCESS(summary.value, summary.tokens, summary.utxos, summary.addresses))
    }
    if (dataUtxosRaw?.error) {
      yield console.log("dataUtxosRaw :: error")
      yield put(AccountActions.BALANCE_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(AccountActions.BALANCE_FAILURE())
    }
  }
}

export function* CONNECTORS_UPDATE_STATUS_SAGA() {
  const connectorsOnline: object[] = yield call(getConnectorsWithStatus)
  const accountsList: AccountTypes.Account[] = yield select((state: RootState) => state.account.accountsList)
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
  const accountsListUpdated: AccountTypes.Account[] = accountsList.map((account) => {
    return {
      ...account,
      online: connectorsOnline.some(
        (i: any) => i.stakeKey === account.stakeKey && i.connectorId === account.connectorId
      ),
    }
  })
  yield put(AccountActions.ACCOUNTS_LIST_SET(accountsListUpdated))
  yield put(AccountActions.ACCOUNT_CURRENT_SET(accountsListUpdated.find((i) => i.id === accountCurrent.id) || null))
}

export function* INIT() {
  yield put(AccountActions.CONNECTORS_UPDATE_STATUS_SAGA())
}

export default function* rootSaga() {
  yield all([
    takeLatest(AccountTypes.Enum.INIT, INIT),
    takeLatest(AccountTypes.Enum.ACCOUNT_INFO_UPDATE_SAGA, ACCOUNT_INFO_UPDATE_SAGA),
    takeLatest(AccountTypes.Enum.BALANCE_UPDATE_SAGA, BALANCE_UPDATE_SAGA),
    takeLatest(AccountTypes.Enum.ACCOUNT_ADD_SAGA, ACCOUNT_ADD_SAGA),
    takeLatest(AccountTypes.Enum.ACCOUNT_REMOVE_SAGA, ACCOUNT_REMOVE_SAGA),
    takeLatest(AccountTypes.Enum.CONNECTORS_UPDATE_STATUS_SAGA, CONNECTORS_UPDATE_STATUS_SAGA),
  ])
}
