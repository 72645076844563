import KoiosTinyClient from "koios-tiny-client"
import config from "@/config/common"
import { GenericAbortSignal } from "axios"

import type * as KoiosExtraRpcTypes from "./types"
import type { AxiosError, AxiosResponse } from "axios"

export type * as KoiosExtraRpcTypes from "./types"
export type { AxiosError, AxiosResponse } from "axios"
export type { KoiosTypes } from "koios-tiny-client"

const { client, methods: Koios } = new KoiosTinyClient(config.apis.outputUrl)

const PoolExplorer = (
  extraParams?: string,
  headers?: object,
  signal?: GenericAbortSignal
): Promise<{ success: AxiosResponse<KoiosExtraRpcTypes.PoolListResponse>; error: AxiosError }> => {
  return client.get(`/pool_explorer?${extraParams ? extraParams : ""}`, { signal, headers })
}

const PoolExplorerBulk = (
  params: { _pool_bech32_ids: string[] },
  extraParams?: string,
  headers?: object,
  signal?: GenericAbortSignal
): Promise<{ success: AxiosResponse<KoiosExtraRpcTypes.PoolListResponse>; error: AxiosError }> => {
  return client.post(
    `/pool_explorer?${extraParams ? extraParams : ""}`,
    { _pool_bech32_ids: params._pool_bech32_ids },
    { signal, headers }
  )
}

export const KoiosExtraRpc = {
  PoolExplorer,
  PoolExplorerBulk,
}

export default Koios
