import React, { useEffect } from "react"
import * as style from "./style.module.scss"
import { SVGCardano } from "@/svg"

const TokenAda = () => {
  return (
    <div className={style.token}>
      <div className={style.tokenLogo}>
        <SVGCardano />
      </div>
      <span className={style.tokenTitle}>
        <strong>ADA</strong>
      </span>
    </div>
  )
}

export default TokenAda
