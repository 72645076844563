import { SettingsTypes } from "./"

export function INIT(): SettingsTypes.AInit {
  return { type: SettingsTypes.Enum.INIT }
}

export function COOKIES_SET(cookiesViewed: boolean): SettingsTypes.ACookiesSet {
  return { type: SettingsTypes.Enum.COOKIES_SET, cookiesViewed }
}

export function THEME_CHANGE(theme: SettingsTypes.Theme): SettingsTypes.AThemeChange {
  return { type: SettingsTypes.Enum.THEME_CHANGE, theme }
}

export function THEME_CHANGE_SAGA(theme: SettingsTypes.Theme): SettingsTypes.AThemeChangeSaga {
  return { type: SettingsTypes.Enum.THEME_CHANGE_SAGA, theme }
}

export function CURRENCY_SET(currency: SettingsTypes.Currency): SettingsTypes.ACurrencySet {
  return { type: SettingsTypes.Enum.CURRENCY_SET, currency }
}

export function UI_SCALE_SET(uiScale: SettingsTypes.UIScale): SettingsTypes.AUIScaleSet {
  return { type: SettingsTypes.Enum.UI_SCALE_SET, uiScale }
}

export function UI_SCALE_UPDATE_SAGA(uiScale: SettingsTypes.UIScale): SettingsTypes.AUIScaleUpdateSaga {
  return { type: SettingsTypes.Enum.UI_SCALE_UPDATE_SAGA, uiScale }
}

export function ADA_DEFAULT_PAGE_SET(adaDefaultPage: SettingsTypes.AdaDefaultPage): SettingsTypes.AAdaDefaultPageSet {
  return { type: SettingsTypes.Enum.ADA_DEFAULT_PAGE_SET, adaDefaultPage }
}

export function TOKENS_DEFAULT_PAGE_SET(
  tokensDefaultPage: SettingsTypes.TokenDefaultPage
): SettingsTypes.ATokensDefaultPageSet {
  return { type: SettingsTypes.Enum.TOKENS_DEFAULT_PAGE_SET, tokensDefaultPage }
}

export function HIDE_BALANCE_SET(hideBalance: SettingsTypes.IHideBalance): SettingsTypes.AHideBalanceSet {
  return { type: SettingsTypes.Enum.HIDE_BALANCE_SET, hideBalance }
}

export function ADA_BOOKMARKS_UPDATE(poolId: string): SettingsTypes.AAdaBookmarksUpdate {
  return { type: SettingsTypes.Enum.ADA_BOOKMARKS_UPDATE, poolId }
}

export function TOKENS_BOOKMARKS_UPDATE(poolId: string): SettingsTypes.ATokensBookmarksUpdate {
  return { type: SettingsTypes.Enum.TOKENS_BOOKMARKS_UPDATE, poolId }
}

export function ADA_VIEW_SET(adaView: SettingsTypes.AdaView): SettingsTypes.AAdaViewSet {
  return { type: SettingsTypes.Enum.ADA_VIEW_SET, adaView }
}

export function TOKENS_VIEW_SET(tokensView: SettingsTypes.TokensView): SettingsTypes.ATokensViewSet {
  return { type: SettingsTypes.Enum.TOKENS_VIEW_SET, tokensView }
}
