import { SettingsTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.settings."
const initialState: SettingsTypes.ISettings = {
  theme: "default",
  cookiesViewed: false,
  currency: "usd",
  uiScale: "large",
  adaDefaultPage: "/",
  tokensDefaultPage: "/",
  hideBalance: {},
  adaBookmarks: [],
  tokensBookmarks: [],
  adaView: "table",
  tokensView: "table",
}

export default (
  state: SettingsTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: SettingsTypes.Actions
): SettingsTypes.ISettings => {
  switch (action.type) {
    case SettingsTypes.Enum.COOKIES_SET:
      setPersistSetting(persistStorePrefix, "cookiesViewed", action.cookiesViewed)
      return { ...state, cookiesViewed: action.cookiesViewed }

    case SettingsTypes.Enum.THEME_CHANGE:
      setPersistSetting(persistStorePrefix, "theme", action.theme)
      return { ...state, theme: action.theme }

    case SettingsTypes.Enum.CURRENCY_SET:
      setPersistSetting(persistStorePrefix, "currency", action.currency)
      return { ...state, currency: action.currency }

    case SettingsTypes.Enum.UI_SCALE_SET:
      setPersistSetting(persistStorePrefix, "uiScale", action.uiScale)
      return { ...state, uiScale: action.uiScale }

    case SettingsTypes.Enum.ADA_DEFAULT_PAGE_SET:
      setPersistSetting(persistStorePrefix, "adaDefaultPage", action.adaDefaultPage)
      return { ...state, adaDefaultPage: action.adaDefaultPage }

    case SettingsTypes.Enum.TOKENS_DEFAULT_PAGE_SET:
      setPersistSetting(persistStorePrefix, "tokensDefaultPage", action.tokensDefaultPage)
      return { ...state, tokensDefaultPage: action.tokensDefaultPage }

    case SettingsTypes.Enum.HIDE_BALANCE_SET:
      setPersistSetting(persistStorePrefix, "hideBalance", action.hideBalance)
      return { ...state, hideBalance: action.hideBalance }

    case SettingsTypes.Enum.TOKENS_BOOKMARKS_UPDATE:
      const __tokensBookmarksUpdated = state.tokensBookmarks.includes(action.poolId)
        ? [...state.tokensBookmarks].filter((poolId) => poolId !== action.poolId)
        : [...state.tokensBookmarks, action.poolId]
      setPersistSetting(persistStorePrefix, "tokensBookmarks", __tokensBookmarksUpdated)
      return { ...state, tokensBookmarks: __tokensBookmarksUpdated }

    case SettingsTypes.Enum.ADA_BOOKMARKS_UPDATE:
      const __adaBookmarksUpdated = state.adaBookmarks.includes(action.poolId)
        ? [...state.adaBookmarks].filter((poolId) => poolId !== action.poolId)
        : [...state.adaBookmarks, action.poolId]
      setPersistSetting(persistStorePrefix, "adaBookmarks", __adaBookmarksUpdated)
      return { ...state, adaBookmarks: __adaBookmarksUpdated }

    case SettingsTypes.Enum.ADA_VIEW_SET:
      setPersistSetting(persistStorePrefix, "adaView", action.adaView)
      return { ...state, adaView: action.adaView }

    case SettingsTypes.Enum.TOKENS_VIEW_SET:
      setPersistSetting(persistStorePrefix, "tokensView", action.tokensView)
      return { ...state, tokensView: action.tokensView }

    default:
      return state
  }
}
