import React, { useEffect, useState } from "react"
import { Tooltip } from "antd"
import Jazzicon from "react-jazzicon"
import { useAppSelector, useAppDispatch } from "@/redux/provider"
import { AccountActions, AccountTypes } from "@/redux/account"
import { numberFromString, truncate } from "@/utils/utils"
import { SVGAdaHandle } from "@/svg"
import * as style from "./style.module.scss"

const Avatar = ({
  account,
  dot = true,
  spinner = true,
  tooltip = true,
  status = true,
  action = true,
}: {
  account: AccountTypes.Account
  dot?: boolean
  spinner?: boolean
  tooltip?: boolean
  status?: boolean
  action?: boolean
}) => {
  const dispatch = useAppDispatch()
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)
  const adaHandle = useAppSelector((state) => state.adaHandle.current)
  const adaHandleDisabled = useAppSelector((state) => state.adaHandle.disabled)
  const balanceLoading = useAppSelector((state) => state.account.balanceLoading)
  const pendingTxs = useAppSelector((state) => state.transaction.pendingTxs)

  const [loading, setLoading] = useState(false)

  const isAdaHandleEnabled = !adaHandleDisabled[account?.id || ""] && adaHandle?.[account?.id || ""]
  const txPending = !!pendingTxs?.[account?.id || ""] && !pendingTxs?.[account?.id || ""]?.sent

  useEffect(() => {
    if (account.id === accountCurrent?.id) {
      if (balanceLoading) {
        setLoading(true)
      } else {
        setTimeout(() => setLoading(false), 300)
      }
    }
  }, [balanceLoading])

  return (
    <div>
      {tooltip && (
        <Tooltip
          title={
            <>
              <div>
                <strong className="me-1">
                  {isAdaHandleEnabled && (
                    <span style={{ position: "relative", top: "-2px" }}>
                      <span className="ray-handle-icon">
                        <SVGAdaHandle />
                      </span>
                      <span className="ray-handle-title">{adaHandle?.[account?.id || ""]?.assetNameAscii}</span>
                    </span>
                  )}
                  {!isAdaHandleEnabled && account?.checksum}
                </strong>
                <strong className="text-nowrap">
                  <sup className="d-inline-flex align-items-center justify-items-center">
                    {account.type === "hw" && (
                      <>
                        <span>{account.connectorId}</span>
                        <span className="ray-dot ray-dot-blue ms-1 me-1" />
                        <span>hardware</span>
                      </>
                    )}
                    {account.type === "readonly" && <span>{account.type}</span>}
                    {account.type === "connector" && (
                      <>
                        <span>{account.connectorId}</span>
                        {account.online && (
                          <>
                            <span className="ray-dot ms-1 me-1" />
                            <span>online</span>
                          </>
                        )}
                        {!account.online && <span className="ray-dot ray-dot-gray ms-1 me-1" />}
                      </>
                    )}
                  </sup>
                </strong>
              </div>
              <div className="font-size-12 text-muted-mono">{truncate(account.stakeKey)}</div>
            </>
          }
          placement="right"
        >
          <div
            className={`${style.avatar} ${account.id === accountCurrent?.id ? style.avatarCurrent : ""} ${
              !status ? style.avatarNoStatus : ""
            }`}
            onClick={() => (action ? dispatch(AccountActions.ACCOUNT_CURRENT_SET(account)) : void 0)}
          >
            {spinner && (
              <div className={`${style.avatarLoading} ${txPending || loading ? style.avatarLoadingActive : ""}`}>
                <i className="ray-spinner ray-spinner-md" />
              </div>
            )}
            {account.type === "hw" && dot && <span className="ray-dot ray-dot-blue" />}
            {account.type === "connector" && dot && account.online && <span className="ray-dot" />}
            <Jazzicon diameter={28} seed={numberFromString(account.stakeKey)} />
          </div>
        </Tooltip>
      )}
      {!tooltip && (
        <div
          className={`${style.avatar} ${account.id === accountCurrent?.id ? style.avatarCurrent : ""} ${
            !status ? style.avatarNoStatus : ""
          }`}
          onClick={() => (action ? dispatch(AccountActions.ACCOUNT_CURRENT_SET(account)) : void 0)}
        >
          {spinner && (
            <div className={`${style.avatarLoading} ${txPending || loading ? style.avatarLoadingActive : ""}`}>
              <i className="ray-spinner ray-spinner-md" />
            </div>
          )}
          {account.type === "hw" && dot && <span className="ray-dot ray-dot-blue" />}
          {account.type === "connector" && dot && account.online && <span className="ray-dot" />}
          <Jazzicon diameter={28} seed={numberFromString(account.stakeKey)} />
        </div>
      )}
    </div>
  )
}

export default Avatar
