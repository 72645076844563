import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { AccountActions, AccountTypes } from "@/redux/account"
import { AdaHandleActions, AdaHandleTypes } from "./"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import { notification } from "antd"
import { truncate } from "@/utils/utils"
import config from "@/config/common"

export function* ADA_HANDLE_UPDATE_SAGA({ list }: AdaHandleTypes.AAdaHandleUpdateSaga) {
  const adaHandle: AdaHandleTypes.IAdaHandleCurrent = yield select((state: RootState) => state.adaHandle.current)
  const balanceBootstrapped: boolean = yield select((state: RootState) => state.account.balanceBootstrapped)

  if (!balanceBootstrapped) return

  const adaHandleListRaw: AdaHandleTypes.IAdaHandleList = yield select((state: RootState) => state.adaHandle.list)
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)

  yield put(
    AdaHandleActions.ADA_HANDLE_LIST_SET({
      ...adaHandleListRaw,
      [accountCurrent?.id]: list,
    })
  )

  if (
    list.length > 0 &&
    (!adaHandle[accountCurrent.id] ||
      !list.find((i) => i.fingerprint === adaHandle?.[accountCurrent.id || ""]?.fingerprint))
  ) {
    yield put(
      AdaHandleActions.ADA_HANDLE_CURRENT_SET({
        ...adaHandle,
        [accountCurrent?.id]: list[0],
      })
    )
  }

  if (list.length <= 0) {
    yield put(
      AdaHandleActions.ADA_HANDLE_CURRENT_SET({
        ...adaHandle,
        [accountCurrent?.id]: null,
      })
    )
  }
}

export default function* rootSaga() {
  yield all([takeEvery(AdaHandleTypes.Enum.ADA_HANDLE_UPDATE_SAGA, ADA_HANDLE_UPDATE_SAGA)])
}
