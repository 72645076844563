import { KoiosTypes, KoiosExtraRpcTypes } from "@/services/koios-ts-client/"
import { RayGraphTypes } from "@/services/raygraph"

/**
 * StakingAda Types
 */

export type PremiumPools = RayGraphTypes.IPoolList

export type AccountHistory = KoiosTypes.IAccountHistory["history"][number]
export type AccountRewards = KoiosTypes.IAccountRewards["rewards"][number]
export type AccountUpdates = KoiosTypes.IAccountUpdates["updates"][number]
export type AccountWithdrawals = {
  tx_hash: string
  amount: string
  epoch_no: number
  block_height: number | null
  tx_timestamp: number
}

export type PoolListItem = KoiosExtraRpcTypes.IPoolList
export type PoolRawData = KoiosTypes.IPoolInfo
export type PoolListRelays = KoiosTypes.IPoolInfo["relays"]
export type PoolBlocks = KoiosTypes.IPoolBlocks
export type PoolHistory = KoiosTypes.IPoolHistory
export type PoolDelegators = KoiosTypes.IPoolDelegators
export type PoolUpdates = KoiosTypes.IPoolUpdates

export interface ISettings {
  premiumPools: PremiumPools[]
  premiumPoolsLoading: boolean

  accountHistoryLoading: boolean
  accountRewardsLoading: boolean
  accountUpdatesLoading: boolean
  accountWithdrawalsLoading: boolean
  accountHistory: AccountHistory[]
  accountRewards: AccountRewards[]
  accountUpdates: AccountUpdates[]
  accountWithdrawals: AccountWithdrawals[]

  poolListLoading: boolean
  poolListDataLoading: boolean
  poolInfoLoading: boolean
  poolBlocksLoading: boolean
  poolHistoryLoading: boolean
  poolDelegatorsLoading: boolean
  poolUpdatesLoading: boolean
  poolRawDataLoading: boolean
  poolList: PoolListItem[]
  poolListTotalResults: number
  poolInfo: PoolListItem | null
  poolBlocks: PoolBlocks[]
  poolHistory: PoolHistory[]
  poolDelegators: PoolDelegators[]
  poolUpdates: PoolUpdates[]
  poolRawData: PoolRawData | null
}

/**
 * StakingAda Actions
 */

export enum Enum {
  CLEAR = "stakingAda/CLEAR",

  PREMIUM_POOLS_UPDATE_SAGA = "stakingAda/PREMIUM_POOLS_UPDATE_SAGA",
  PREMIUM_POOLS_CLEAR = "stakingAda/PREMIUM_POOLS_CLEAR",
  PREMIUM_POOLS_REQUEST = "stakingAda/PREMIUM_POOLS_REQUEST",
  PREMIUM_POOLS_FAILURE = "stakingAda/PREMIUM_POOLS_FAILURE",
  PREMIUM_POOLS_SUCCESS = "stakingAda/PREMIUM_POOLS_SUCCESS",

  ACCOUNT_HISTORY_UPDATE_SAGA = "stakingAda/ACCOUNT_HISTORY_UPDATE_SAGA",
  ACCOUNT_HISTORY_CLEAR = "stakingAda/ACCOUNT_HISTORY_CLEAR",
  ACCOUNT_HISTORY_REQUEST = "stakingAda/ACCOUNT_HISTORY_REQUEST",
  ACCOUNT_HISTORY_FAILURE = "stakingAda/ACCOUNT_HISTORY_FAILURE",
  ACCOUNT_HISTORY_SUCCESS = "stakingAda/ACCOUNT_HISTORY_SUCCESS",

  ACCOUNT_REWARDS_UPDATE_SAGA = "stakingAda/ACCOUNT_REWARDS_UPDATE_SAGA",
  ACCOUNT_REWARDS_CLEAR = "stakingAda/ACCOUNT_REWARDS_CLEAR",
  ACCOUNT_REWARDS_REQUEST = "stakingAda/ACCOUNT_REWARDS_REQUEST",
  ACCOUNT_REWARDS_FAILURE = "stakingAda/ACCOUNT_REWARDS_FAILURE",
  ACCOUNT_REWARDS_SUCCESS = "stakingAda/ACCOUNT_REWARDS_SUCCESS",

  ACCOUNT_UPDATES_UPDATE_SAGA = "stakingAda/ACCOUNT_UPDATES_UPDATE_SAGA",
  ACCOUNT_UPDATES_CLEAR = "stakingAda/ACCOUNT_UPDATES_CLEAR",
  ACCOUNT_UPDATES_REQUEST = "stakingAda/ACCOUNT_UPDATES_REQUEST",
  ACCOUNT_UPDATES_FAILURE = "stakingAda/ACCOUNT_UPDATES_FAILURE",
  ACCOUNT_UPDATES_SUCCESS = "stakingAda/ACCOUNT_UPDATES_SUCCESS",

  ACCOUNT_WITHDRAWALS_UPDATE_SAGA = "stakingAda/ACCOUNT_WITHDRAWALS_UPDATE_SAGA",
  ACCOUNT_WITHDRAWALS_CLEAR = "stakingAda/ACCOUNT_WITHDRAWALS_CLEAR",
  ACCOUNT_WITHDRAWALS_REQUEST = "stakingAda/ACCOUNT_WITHDRAWALS_REQUEST",
  ACCOUNT_WITHDRAWALS_FAILURE = "stakingAda/ACCOUNT_WITHDRAWALS_FAILURE",
  ACCOUNT_WITHDRAWALS_SUCCESS = "stakingAda/ACCOUNT_WITHDRAWALS_SUCCESS",

  POOL_LIST_UPDATE_SAGA = "stakingAda/POOL_LIST_UPDATE_SAGA",
  POOL_LIST_UPDATE_BULK_SAGA = "stakingAda/POOL_LIST_UPDATE_BULK_SAGA",
  POOL_LIST_CLEAR = "stakingAda/POOL_LIST_CLEAR",
  POOL_LIST_REQUEST = "stakingAda/POOL_LIST_REQUEST",
  POOL_LIST_FAILURE = "stakingAda/POOL_LIST_FAILURE",
  POOL_LIST_SUCCESS = "stakingAda/POOL_LIST_SUCCESS",

  POOL_INFO_UPDATE_SAGA = "stakingAda/POOL_INFO_UPDATE_SAGA",
  POOL_INFO_CLEAR = "stakingAda/POOL_INFO_CLEAR",
  POOL_INFO_REQUEST = "stakingAda/POOL_INFO_REQUEST",
  POOL_INFO_FAILURE = "stakingAda/POOL_INFO_FAILURE",
  POOL_INFO_SUCCESS = "stakingAda/POOL_INFO_SUCCESS",

  POOL_BLOCKS_UPDATE_SAGA = "stakingAda/POOL_BLOCKS_UPDATE_SAGA",
  POOL_BLOCKS_CLEAR = "stakingAda/POOL_BLOCKS_CLEAR",
  POOL_BLOCKS_REQUEST = "stakingAda/POOL_BLOCKS_REQUEST",
  POOL_BLOCKS_FAILURE = "stakingAda/POOL_BLOCKS_FAILURE",
  POOL_BLOCKS_SUCCESS = "stakingAda/POOL_BLOCKS_SUCCESS",

  POOL_HISTORY_UPDATE_SAGA = "stakingAda/POOL_HISTORY_UPDATE_SAGA",
  POOL_HISTORY_CLEAR = "stakingAda/POOL_HISTORY_CLEAR",
  POOL_HISTORY_REQUEST = "stakingAda/POOL_HISTORY_REQUEST",
  POOL_HISTORY_FAILURE = "stakingAda/POOL_HISTORY_FAILURE",
  POOL_HISTORY_SUCCESS = "stakingAda/POOL_HISTORY_SUCCESS",

  POOL_DELEGATORS_UPDATE_SAGA = "stakingAda/POOL_DELEGATORS_UPDATE_SAGA",
  POOL_DELEGATORS_CLEAR = "stakingAda/POOL_DELEGATORS_CLEAR",
  POOL_DELEGATORS_REQUEST = "stakingAda/POOL_DELEGATORS_REQUEST",
  POOL_DELEGATORS_FAILURE = "stakingAda/POOL_DELEGATORS_FAILURE",
  POOL_DELEGATORS_SUCCESS = "stakingAda/POOL_DELEGATORS_SUCCESS",

  POOL_UPDATES_UPDATE_SAGA = "stakingAda/POOL_UPDATES_UPDATE_SAGA",
  POOL_UPDATES_CLEAR = "stakingAda/POOL_UPDATES_CLEAR",
  POOL_UPDATES_REQUEST = "stakingAda/POOL_UPDATES_REQUEST",
  POOL_UPDATES_FAILURE = "stakingAda/POOL_UPDATES_FAILURE",
  POOL_UPDATES_SUCCESS = "stakingAda/POOL_UPDATES_SUCCESS",

  POOL_RAW_DATA_UPDATE_SAGA = "stakingAda/POOL_RAW_DATA_UPDATE_SAGA",
  POOL_RAW_DATA_CLEAR = "stakingAda/POOL_RAW_DATA_CLEAR",
  POOL_RAW_DATA_REQUEST = "stakingAda/POOL_RAW_DATA_REQUEST",
  POOL_RAW_DATA_FAILURE = "stakingAda/POOL_RAW_DATA_FAILURE",
  POOL_RAW_DATA_SUCCESS = "stakingAda/POOL_RAW_DATA_SUCCESS",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type APremiumPoolsUpdateSaga = {
  type: typeof Enum.PREMIUM_POOLS_UPDATE_SAGA
}

export type APremiumPoolsClear = {
  type: typeof Enum.PREMIUM_POOLS_CLEAR
}

export type APremiumPoolsRequest = {
  type: typeof Enum.PREMIUM_POOLS_REQUEST
}

export type APremiumPoolsFailure = {
  type: typeof Enum.PREMIUM_POOLS_FAILURE
}

export type APremiumPoolsSuccess = {
  type: typeof Enum.PREMIUM_POOLS_SUCCESS
  premiumPools: PremiumPools[]
}

export type AAccountHistoryUpdateSaga = {
  type: typeof Enum.ACCOUNT_HISTORY_UPDATE_SAGA
}

export type AAccountHistoryClear = {
  type: typeof Enum.ACCOUNT_HISTORY_CLEAR
}

export type AAccountHistoryRequest = {
  type: typeof Enum.ACCOUNT_HISTORY_REQUEST
}

export type AAccountHistoryFailure = {
  type: typeof Enum.ACCOUNT_HISTORY_FAILURE
}

export type AAccountHistorySuccess = {
  type: typeof Enum.ACCOUNT_HISTORY_SUCCESS
  accountHistory: AccountHistory[]
}

export type AAccountRewardsUpdateSaga = {
  type: typeof Enum.ACCOUNT_REWARDS_UPDATE_SAGA
}

export type AAccountRewardsClear = {
  type: typeof Enum.ACCOUNT_REWARDS_CLEAR
}

export type AAccountRewardsRequest = {
  type: typeof Enum.ACCOUNT_REWARDS_REQUEST
}

export type AAccountRewardsFailure = {
  type: typeof Enum.ACCOUNT_REWARDS_FAILURE
}

export type AAccountRewardsSuccess = {
  type: typeof Enum.ACCOUNT_REWARDS_SUCCESS
  accountRewards: AccountRewards[]
}

export type AAccountUpdatesUpdateSaga = {
  type: typeof Enum.ACCOUNT_UPDATES_UPDATE_SAGA
}

export type AAccountUpdatesClear = {
  type: typeof Enum.ACCOUNT_UPDATES_CLEAR
}

export type AAccountUpdatesRequest = {
  type: typeof Enum.ACCOUNT_UPDATES_REQUEST
}

export type AAccountUpdatesFailure = {
  type: typeof Enum.ACCOUNT_UPDATES_FAILURE
}

export type AAccountUpdatesSuccess = {
  type: typeof Enum.ACCOUNT_UPDATES_SUCCESS
  accountUpdates: AccountUpdates[]
}

export type AAccountWithdrawalsUpdateSaga = {
  type: typeof Enum.ACCOUNT_WITHDRAWALS_UPDATE_SAGA
}

export type AAccountWithdrawalsClear = {
  type: typeof Enum.ACCOUNT_WITHDRAWALS_CLEAR
}

export type AAccountWithdrawalsRequest = {
  type: typeof Enum.ACCOUNT_WITHDRAWALS_REQUEST
}

export type AAccountWithdrawalsFailure = {
  type: typeof Enum.ACCOUNT_WITHDRAWALS_FAILURE
}

export type AAccountWithdrawalsSuccess = {
  type: typeof Enum.ACCOUNT_WITHDRAWALS_SUCCESS
  accountWithdrawals: AccountWithdrawals[]
}

export type APoolListUpdateSaga = {
  type: typeof Enum.POOL_LIST_UPDATE_SAGA
  paramsString?: string
  headers?: any
}

export type APoolListUpdateBulkSaga = {
  type: typeof Enum.POOL_LIST_UPDATE_BULK_SAGA
  poolIds: string[]
  paramsString?: string
  headers?: any
}

export type APoolListClear = {
  type: typeof Enum.POOL_LIST_CLEAR
}

export type APoolListRequest = {
  type: typeof Enum.POOL_LIST_REQUEST
}

export type APoolListFailure = {
  type: typeof Enum.POOL_LIST_FAILURE
}

export type APoolListSuccess = {
  type: typeof Enum.POOL_LIST_SUCCESS
  poolList: PoolListItem[]
  poolListTotalResults: number
}

export type APoolInfoUpdateSaga = {
  type: typeof Enum.POOL_INFO_UPDATE_SAGA
  poolId: string
}

export type APoolInfoClear = {
  type: typeof Enum.POOL_INFO_CLEAR
}

export type APoolInfoRequest = {
  type: typeof Enum.POOL_INFO_REQUEST
}

export type APoolInfoFailure = {
  type: typeof Enum.POOL_INFO_FAILURE
}

export type APoolInfoSuccess = {
  type: typeof Enum.POOL_INFO_SUCCESS
  poolInfo: PoolListItem
}

export type APoolBlocksUpdateSaga = {
  type: typeof Enum.POOL_BLOCKS_UPDATE_SAGA
  poolId: string
  epochNo?: number
}

export type APoolBlocksClear = {
  type: typeof Enum.POOL_BLOCKS_CLEAR
}

export type APoolBlocksRequest = {
  type: typeof Enum.POOL_BLOCKS_REQUEST
}

export type APoolBlocksFailure = {
  type: typeof Enum.POOL_BLOCKS_FAILURE
}

export type APoolBlocksSuccess = {
  type: typeof Enum.POOL_BLOCKS_SUCCESS
  poolBlocks: PoolBlocks[]
}

export type APoolHistoryUpdateSaga = {
  type: typeof Enum.POOL_HISTORY_UPDATE_SAGA
  poolId: string
}

export type APoolHistoryClear = {
  type: typeof Enum.POOL_HISTORY_CLEAR
}

export type APoolHistoryRequest = {
  type: typeof Enum.POOL_HISTORY_REQUEST
}

export type APoolHistoryFailure = {
  type: typeof Enum.POOL_HISTORY_FAILURE
}

export type APoolHistorySuccess = {
  type: typeof Enum.POOL_HISTORY_SUCCESS
  poolHistory: PoolHistory[]
}

export type APoolDelegatorsUpdateSaga = {
  type: typeof Enum.POOL_DELEGATORS_UPDATE_SAGA
  poolId: string
}

export type APoolDelegatorsClear = {
  type: typeof Enum.POOL_DELEGATORS_CLEAR
}

export type APoolDelegatorsRequest = {
  type: typeof Enum.POOL_DELEGATORS_REQUEST
}

export type APoolDelegatorsFailure = {
  type: typeof Enum.POOL_DELEGATORS_FAILURE
}

export type APoolDelegatorsSuccess = {
  type: typeof Enum.POOL_DELEGATORS_SUCCESS
  poolDelegators: PoolDelegators[]
}

export type APoolUpdatesUpdateSaga = {
  type: typeof Enum.POOL_UPDATES_UPDATE_SAGA
  poolId: string
}

export type APoolUpdatesClear = {
  type: typeof Enum.POOL_UPDATES_CLEAR
}

export type APoolUpdatesRequest = {
  type: typeof Enum.POOL_UPDATES_REQUEST
}

export type APoolUpdatesFailure = {
  type: typeof Enum.POOL_UPDATES_FAILURE
}

export type APoolUpdatesSuccess = {
  type: typeof Enum.POOL_UPDATES_SUCCESS
  poolUpdates: PoolUpdates[]
}

export type APoolRawDataUpdateSaga = {
  type: typeof Enum.POOL_RAW_DATA_UPDATE_SAGA
  poolId: string
}

export type APoolRawDataClear = {
  type: typeof Enum.POOL_RAW_DATA_CLEAR
}

export type APoolRawDataRequest = {
  type: typeof Enum.POOL_RAW_DATA_REQUEST
}

export type APoolRawDataFailure = {
  type: typeof Enum.POOL_RAW_DATA_FAILURE
}

export type APoolRawDataSuccess = {
  type: typeof Enum.POOL_RAW_DATA_SUCCESS
  poolRawData: PoolRawData
}

export type Actions =
  | AClear
  | APremiumPoolsUpdateSaga
  | APremiumPoolsClear
  | APremiumPoolsRequest
  | APremiumPoolsFailure
  | APremiumPoolsSuccess
  | AAccountHistoryUpdateSaga
  | AAccountHistoryClear
  | AAccountHistoryRequest
  | AAccountHistoryFailure
  | AAccountHistorySuccess
  | AAccountRewardsUpdateSaga
  | AAccountRewardsClear
  | AAccountRewardsRequest
  | AAccountRewardsFailure
  | AAccountRewardsSuccess
  | AAccountUpdatesUpdateSaga
  | AAccountUpdatesClear
  | AAccountUpdatesRequest
  | AAccountUpdatesFailure
  | AAccountUpdatesSuccess
  | AAccountWithdrawalsUpdateSaga
  | AAccountWithdrawalsClear
  | AAccountWithdrawalsRequest
  | AAccountWithdrawalsFailure
  | AAccountWithdrawalsSuccess
  | APoolListUpdateSaga
  | APoolListUpdateBulkSaga
  | APoolListClear
  | APoolListRequest
  | APoolListFailure
  | APoolListSuccess
  | APoolInfoUpdateSaga
  | APoolInfoClear
  | APoolInfoRequest
  | APoolInfoFailure
  | APoolInfoSuccess
  | APoolBlocksUpdateSaga
  | APoolBlocksClear
  | APoolBlocksRequest
  | APoolBlocksFailure
  | APoolBlocksSuccess
  | APoolHistoryUpdateSaga
  | APoolHistoryClear
  | APoolHistoryRequest
  | APoolHistoryFailure
  | APoolHistorySuccess
  | APoolDelegatorsUpdateSaga
  | APoolDelegatorsClear
  | APoolDelegatorsRequest
  | APoolDelegatorsFailure
  | APoolDelegatorsSuccess
  | APoolUpdatesUpdateSaga
  | APoolUpdatesClear
  | APoolUpdatesRequest
  | APoolUpdatesFailure
  | APoolUpdatesSuccess
  | APoolRawDataUpdateSaga
  | APoolRawDataClear
  | APoolRawDataRequest
  | APoolRawDataFailure
  | APoolRawDataSuccess
