import { KoiosTypes } from "@/services/koios-ts-client"

/**
 * Network Types
 */

export type NetworkTip = KoiosTypes.ITip
export type EpochCurrent = KoiosTypes.IEpochInfo
export interface IExchangeRateCurrencies {
  usd: number
  eur: number
  jpy: number
  cny: number
}
export interface IExchangeRates {
  bitcoin: IExchangeRateCurrencies
  ethereum: IExchangeRateCurrencies
  cardano: IExchangeRateCurrencies
  "ray-network": IExchangeRateCurrencies
}

export interface ISettings {
  online: boolean
  loading: boolean
  tip: NetworkTip | null
  epochCurrent: EpochCurrent | null
  exchangeRates: IExchangeRates | null
}

/**
 * Network Actions
 */

export enum Enum {
  TIP_SET = "network/TIP_SET",
  NETWORK_STATUS_SET = "network/NETWORK_STATUS_SET",
  EPOCH_CURRENT_SET = "network/EPOCH_CURRENT_SET",
  NETWORK_UPDATE_SAGA = "network/NETWORK_UPDATE_SAGA",
  EXCHANGE_RATES_UPDATE_SAGA = "network/EXCHANGE_RATES_UPDATE_SAGA",
  EXCHANGE_RATES_SET = "network/EXCHANGE_RATES_SET",
}

export type ATipSet = {
  type: typeof Enum.TIP_SET
  tip: NetworkTip | null
}

export type ANetworkStatusSet = {
  type: typeof Enum.NETWORK_STATUS_SET
  online: boolean
}

export type AEpochCurrentSet = {
  type: typeof Enum.EPOCH_CURRENT_SET
  epochCurrent: EpochCurrent
}

export type ANetworkUpdateSaga = {
  type: typeof Enum.NETWORK_UPDATE_SAGA
}

export type AExchangeRatesUpdateSaga = {
  type: typeof Enum.EXCHANGE_RATES_UPDATE_SAGA
}

export type AExchangeRatesSet = {
  type: typeof Enum.EXCHANGE_RATES_SET
  exchangeRates: IExchangeRates
}

export type Actions =
  | ATipSet
  | ANetworkStatusSet
  | AEpochCurrentSet
  | ANetworkUpdateSaga
  | AExchangeRatesUpdateSaga
  | AExchangeRatesSet
