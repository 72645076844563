import { all, takeEvery, put, call, select, fork, takeLatest, delay, take } from "redux-saga/effects"
import store from "store"
import type { RootState } from "../provider"
import Koios, { KoiosTypes } from "@/services/koios-ts-client"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { StakingTokensActions, StakingTokensTypes } from "@/redux/stakingTokens"
import { AccountTypes } from "@/redux/account"

const __accountActivity: any = {
  success: [
    {
      id: 1,
      action: "harvest",
      amount: [
        {
          amount: "122617125",
          token: "XRAY",
        },
        {
          amount: "321451",
          token: "COMBO",
        },
      ],
      hash: "db0900c967f1c52b67f1c52b",
      date: 1661350707,
    },
    {
      id: 2,
      action: "remove",
      amount: [
        {
          amount: "10000000",
          token: "XRAY",
        },
      ],
      hash: "335b1c8e5a6693fc5a6693fc",
      date: 1658565145,
    },
    {
      id: 3,
      action: "add",
      amount: [
        {
          amount: "87000000000",
          token: "XRAY",
        },
      ],
      hash: "1521973bde216c31de216c31",
      date: 1653984694,
    },
  ],
}

export function* ACCOUNT_ACTIVITY_UPDATE_SAGA() {
  yield put(StakingTokensActions.ACCOUNT_ACTIVITY_CLEAR())
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
  if (!accountCurrent) return

  yield put(StakingTokensActions.ACCOUNT_HISTORY_REQUEST())
  yield delay(2000)
  const dataAccountActivity = __accountActivity
  if (dataAccountActivity?.success) {
    yield put(StakingTokensActions.ACCOUNT_HISTORY_SUCCESS(dataAccountActivity?.success))
  }
  if (dataAccountActivity?.error) {
    yield put(StakingTokensActions.ACCOUNT_HISTORY_FAILURE())
    yield console.log("dataAccountUpdates :: error")
  }
}

export default function* rootSaga() {
  yield all([takeLatest(StakingTokensTypes.Enum.ACCOUNT_ACTIVITY_UPDATE_SAGA, ACCOUNT_ACTIVITY_UPDATE_SAGA)])
}
