import React from "react"
import { Tooltip } from "antd"
import { amountWithCommas } from "@/utils/utils"
import * as style from "./style.module.scss"

const InformerCurrency = ({
  amount,
  currency,
  title,
  help,
  decimals = 3,
}: {
  amount: number
  title: string
  currency?: string
  help?: string
  decimals?: number
}) => {
  const [a, b] = (amount || 0).toFixed(decimals).split(".")
  return (
    <div className={style.informer}>
      <div className={style.body}>
        <strong>
          {currency}
          {amountWithCommas(a)}
          {b && `.${b}`}
        </strong>
      </div>
      {title && (
        <div className={style.title}>
          {title}{" "}
          {help && (
            <Tooltip title={help}>
              <i className="ri ri-info ms-1" />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default InformerCurrency
