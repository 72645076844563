import React, { useState, useEffect } from "react"
import { Statistic, Tooltip } from "antd"
import { amountWithCommas } from "@/utils/utils"
import * as style from "./style.module.scss"
import { useAppSelector, useAppDispatch } from "@/redux/provider"
import { SVGRay } from "@/svg"
import { Link } from "gatsby"
import { AccountActions } from "@/redux/account"
import AccountAvatar from "@/components/common/AccountAvatar"

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const networkTip = useAppSelector((state) => state.network.tip)
  const networkEpochCurrent = useAppSelector((state) => state.network.epochCurrent)
  const accountsList = useAppSelector((state) => state.account.accountsList)

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(true)
    const int = setInterval(() => {
      setAnimate(false)
    }, 1500)
    return () => {
      clearInterval(int)
    }
  }, [networkTip?.block_no])

  return (
    <div className={style.sidebar}>
      <div className="mb-3">
        <Tooltip title="by Ray Network" placement="right">
          <Link className="d-flex" to="/">
            <SVGRay className={style.logo} alt="RaySwap" />
          </Link>
        </Tooltip>
      </div>
      <div>
        <div>
          {accountsList.map((account) => {
            return (
              <div key={account.id} className="mb-2">
                <AccountAvatar account={account} />
              </div>
            )
          })}
        </div>
        <div>
          <Tooltip title="Connect Wallet" placement="right">
            <div
              className={style.connect}
              onClick={() => dispatch(AccountActions.MODAL_ACCOUNT_SET({ open: true, type: "connect" }))}
            >
              <i className="ri ri-add" />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={style.sidebarBottom}>
        <div>
          <span className={`${style.statusDot} ${style.statusDotGreen} ${animate ? style.statusDotAnimate : ""}`} />
        </div>
        <div className="text-nowrap">
          <div>Block: {amountWithCommas(networkTip?.block_no || 0)}</div>
          <div style={{ height: "13rem" }}>
            Epoch: {amountWithCommas(networkTip?.epoch_no || 0)} ·{" "}
            <div className="ray-count">
              <Statistic.Countdown
                value={new Date((networkEpochCurrent?.end_time || 0) * 1000).getTime()}
                format="D[d] HH[h] mm[m] ss[s]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
