import React from "react"
import { SVGCardano } from "@/svg"
import * as style from "./style.module.scss"
import packageInfo from "../../../../package.json"
import { Link } from "gatsby"

const links = [
  // { link: "https://raynetwork.io/", title: "Ray Network" },
  { link: "https://raywallet.io/", title: "RayWallet" },
  { link: "https://rayswap.io/", title: "RaySwap" },
  { link: "https://raystake.io/", title: "RayStake" },
  { link: "https://raypad.io/", title: "RayPad" },
  { link: "https://raygraph.io/", title: "RayGraph" },
  { link: "https://raydata.io/", title: "RayData" },
  { link: "https://minterr.io/", title: "Minterr.io" },
  { link: "https://cardanolist.io/", title: "CardanoList.io" },
  { link: "https://pigz.io/", title: "Pigz.io" },
  { link: "https://ray.foundation/", title: "Ray Foundation" },
]

const Footer = () => {
  return (
    <div className={style.footer}>
      <div>
        {links.map((link, i) => {
          return (
            <span key={i}>
              <a href={link.link} rel="noopener noreferrer" target="_blank" className={style.link}>
                <strong>{link.title}</strong>
              </a>
              {links.length > i + 1 && " · "}
            </span>
          )
        })}
      </div>
      <div>
        {new Date().getFullYear()} &copy;{" "}
        <a href="https://raynetwork.io" target="_blank" rel="noopener noreferrer">
          <strong>Ray Network</strong>
        </a>{" "}
        {/* ·{" "}
        <Link to="/faq/">
          <strong>FAQ</strong>
        </Link>{" "} */}
        · Advanced Ecosystem for Cardano Blockchain Platform
      </div>
      <div className="mb-1">
        RayStake Version {packageInfo?.version} · Powered with{" "}
        <a href="https://cardano.org/" rel="noopener noreferrer" target="_blank">
          <span className={style.footerCardano}>
            <SVGCardano />
          </span>
          <strong>Cardano</strong>
        </a>
      </div>
    </div>
  )
}

export default Footer
