import { NetworkTypes } from "./"

export function TIP_SET(tip: NetworkTypes.NetworkTip): NetworkTypes.ATipSet {
  return { type: NetworkTypes.Enum.TIP_SET, tip }
}

export function NETWORK_STATUS_SET(online: boolean): NetworkTypes.ANetworkStatusSet {
  return { type: NetworkTypes.Enum.NETWORK_STATUS_SET, online }
}

export function EPOCH_CURRENT_SET(epochCurrent: NetworkTypes.EpochCurrent): NetworkTypes.AEpochCurrentSet {
  return { type: NetworkTypes.Enum.EPOCH_CURRENT_SET, epochCurrent }
}

export function NETWORK_UPDATE_SAGA(): NetworkTypes.ANetworkUpdateSaga {
  return { type: NetworkTypes.Enum.NETWORK_UPDATE_SAGA }
}

export function EXCHANGE_RATES_UPDATE_SAGA(): NetworkTypes.AExchangeRatesUpdateSaga {
  return { type: NetworkTypes.Enum.EXCHANGE_RATES_UPDATE_SAGA }
}
export function EXCHANGE_RATES_SET(exchangeRates: NetworkTypes.IExchangeRates): NetworkTypes.AExchangeRatesSet {
  return { type: NetworkTypes.Enum.EXCHANGE_RATES_SET, exchangeRates }
}
