import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import { CachedTokensActions, CachedTokensTypes } from "./"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import { dbTokensCache } from "@/services/localforage"

export function* CACHED_TOKENS_UPDATE_SAGA({ tokensToAdd }: CachedTokensTypes.ACachedTokensUpdateSaga) {
  const __cachedTokens: CachedTokensTypes.ICachedTokens = yield select(
    (state: RootState) => state.cachedTokens.cachedTokens
  )
  const __notCachedTokens = tokensToAdd.filter((token) => !Object.keys(__cachedTokens).includes(token.fingerprint))
  if (!__notCachedTokens.length) return

  const __storedTokensNotCached: CachedTokensTypes.IAssetInformationCached[] = yield all(
    __notCachedTokens.map((i) => call([dbTokensCache, dbTokensCache.getItem], i.fingerprint))
  )

  const [__loadFromStore, __loadFromNetwork] = tokensToAdd.reduce(
    (acc, i) => {
      const storedToken = __storedTokensNotCached
        .filter((i) => i)
        .find((item) => item.data?.fingerprint === i.fingerprint)
      // expires after 14 days
      if (storedToken?.date && Date.now() - storedToken.date > 1000 * 60 * 60 * 24 * 14) {
        acc[1].push(i)
        return acc
      }
      storedToken ? acc[0].push(storedToken) : acc[1].push(i)
      return acc
    },
    [[], []] as [CachedTokensTypes.IAssetInformationCached[], CachedTokensTypes.ITokenRaw[]]
  )

  yield put(
    CachedTokensActions.CACHED_TOKENS_ADD(
      __loadFromStore.reduce((acc, i) => {
        if (i.data) acc[i.data?.fingerprint] = i
        return acc
      }, {} as CachedTokensTypes.ICachedTokens)
    )
  )

  if (!__loadFromNetwork.length) return
  const __loadFromNetworkIds = __loadFromNetwork.map((i) => [i.policyId, i.assetName || ""])
  const __loadFromNetworkFingerprints = __loadFromNetwork.map((i) => i.fingerprint)

  yield put(
    CachedTokensActions.CACHED_TOKENS_ADD(
      __loadFromNetwork.reduce((acc, i) => {
        acc[i.fingerprint] = {
          date: Date.now(),
          loading: true,
          data: null,
        }
        return acc
      }, {} as CachedTokensTypes.ICachedTokens)
    )
  )

  try {
    const dataAssetInfo: {
      success?: AxiosResponse<KoiosTypes.AssetInfoBulkResponse>
      error?: AxiosError
    } = yield call(
      Koios.AssetInfoBulk,
      {
        _asset_list: __loadFromNetworkIds,
      },
      `&select=` +
        `asset_name,` +
        `asset_name_ascii,` +
        `burn_cnt,` +
        `creation_time,` +
        `fingerprint,` +
        `mint_cnt,` +
        `minting_tx_hash,` +
        `minting_tx_metadata,` +
        `policy_id,` +
        `token_registry_metadata->decimals,` +
        `token_registry_metadata->description,` +
        `token_registry_metadata->name,` +
        `token_registry_metadata->ticker,` +
        `token_registry_metadata->url,` +
        `total_supply`
    )
    if (dataAssetInfo?.success) {
      const __data = dataAssetInfo?.success?.data?.reduce((acc, i) => {
        acc[i.fingerprint] = {
          date: Date.now(),
          loading: false,
          data: {
            asset_name: i.asset_name,
            asset_name_ascii: i.asset_name_ascii,
            burn_cnt: i.burn_cnt,
            creation_time: i.creation_time,
            fingerprint: i.fingerprint,
            mint_cnt: i.mint_cnt,
            minting_tx_hash: i.minting_tx_hash,
            minting_tx_metadata: i.minting_tx_metadata,
            policy_id: i.policy_id,
            token_registry_metadata: (i as any).ticker
              ? {
                  name: (i as any).name,
                  description: (i as any).description,
                  ticker: (i as any).ticker,
                  url: (i as any).url,
                  logo: "",
                  decimals: (i as any).decimals,
                }
              : null,
            total_supply: i.total_supply,
          },
        }
        return acc
      }, {} as CachedTokensTypes.ICachedTokens)
      yield all(Object.entries(__data).map(([key, value]) => call([dbTokensCache, dbTokensCache.setItem], key, value)))
      yield put(CachedTokensActions.CACHED_TOKENS_ADD(__data))
    }
    if (dataAssetInfo?.error) {
      // clear tokens on error
      yield put(CachedTokensActions.CACHED_TOKENS_REMOVE(__loadFromNetworkFingerprints))
      yield console.log("dataAssetInfo :: error")
    }
  } catch {
    // clear tokens on error
    yield put(CachedTokensActions.CACHED_TOKENS_REMOVE(__loadFromNetworkFingerprints))
  }
}

export default function* rootSaga() {
  yield all([takeEvery(CachedTokensTypes.Enum.CACHED_TOKENS_UPDATE_SAGA, CACHED_TOKENS_UPDATE_SAGA)])
}
