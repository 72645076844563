import { KoiosTypes } from "@/services/koios-ts-client"

/**
 * Cached Tokens Types
 */

export type AssetInformation = KoiosTypes.IAssetInfo
export interface IAssetInformationCached {
  date: number
  loading: boolean
  data: AssetInformation | null
}
export interface ICachedTokens {
  [key: string]: IAssetInformationCached
}
export interface ITokenRaw {
  assetId: string
  assetName: string | null
  assetNameAscii: string | null
  fingerprint: string
  policyId: string
}

export interface ISettings {
  cachedTokens: ICachedTokens
}

/**
 * Cached Tokens Actions
 */

export enum Enum {
  CLEAR = "adaHandle/CLEAR",
  CACHED_TOKENS_ADD = "network/CACHED_TOKENS_ADD",
  CACHED_TOKENS_REMOVE = "network/CACHED_TOKENS_REMOVE",
  CACHED_TOKENS_UPDATE_SAGA = "network/CACHED_TOKENS_UPDATE_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type ACachedTokensAdd = {
  type: typeof Enum.CACHED_TOKENS_ADD
  cachedTokens: ICachedTokens
}

export type ACachedTokensRemove = {
  type: typeof Enum.CACHED_TOKENS_REMOVE
  cachedTokens: string[]
}

export type ACachedTokensUpdateSaga = {
  type: typeof Enum.CACHED_TOKENS_UPDATE_SAGA
  tokensToAdd: ITokenRaw[]
}

export type Actions = AClear | ACachedTokensAdd | ACachedTokensRemove | ACachedTokensUpdateSaga
