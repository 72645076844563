import axios, { AxiosError, AxiosResponse, GenericAbortSignal } from "axios"
import config from "@/config/common"
import * as KupoTypes from "./types"
export type * as KupoTypes from "./types"

export const kupoClient = axios.create({
  baseURL: config.apis.kupoUrl,
})

kupoClient.interceptors.response.use(
  (response: AxiosResponse): any => {
    return {
      success: response,
    }
  },
  (error: AxiosError): { error: AxiosError } => {
    return {
      error,
    }
  }
)

export const utxosByStakeKey = async (
  params?: { _stake_key?: string },
  extraParams?: string,
  headers?: object,
  signal?: GenericAbortSignal
): Promise<{ success: AxiosResponse<KupoTypes.UtxosResponse>; error: AxiosError }> => {
  return kupoClient.get(`/matches/${params?._stake_key || ""}${extraParams ? extraParams : ""}`, { signal, headers })
}

export default {
  utxosByStakeKey,
}
