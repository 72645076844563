import React, { useEffect, useState } from "react"
import { Badge, Button, Tabs, message, Tooltip, Form, Input, Radio, Spin, Popconfirm } from "antd"
import { SVGCardano, SVGAdaHandle } from "@/svg"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { truncate, randomString } from "@/utils/utils"
import { getConnectors } from "@/services/connectors"
import { walletChecksum } from "@emurgo/cip4-js"
import { connectors, IConnector } from "@/config/connectors"
import config from "@/config/common"
import { AccountActions, AccountTypes } from "@/redux/account"
import AccountAvatar from "@/components/common/AccountAvatar"
import * as style from "./style.module.scss"
import { bech32 } from "bech32"
import { Buffer } from "buffer"

const ModalWalletConnect = () => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [connectorFilter, setConnectorFilter] = useState("popular")
  const [connectorLoading, setConnectorLoading] = useState("")

  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)
  const accountsList = useAppSelector((state) => state.account.accountsList)
  const adaHandle = useAppSelector((state) => state.adaHandle.current)
  const adaHandleDisabled = useAppSelector((state) => state.adaHandle.disabled)

  const connectConnector = async (connector: IConnector) => {
    if (connectorLoading) return
    setConnectorLoading(connector.connectorId)
    await window?.cardano[connector.connectorId]
      .enable()
      .then(async (instance: any) => {
        const [key] = await instance.getRewardAddresses()
        console.log(key)
        const networkId = await instance.getNetworkId()
        if (networkId.toString() !== config.network.id.toString()) {
          message.error("Wrong Wallet Network")
          return
        }
        const stakeKey = bech32.encode(networkId.toString() === "1" ? "stake" : "stake_test", bech32.toWords(Buffer.from(key, "hex")))
        const { TextPart } = walletChecksum(stakeKey)
        dispatch(
          AccountActions.ACCOUNT_ADD_SAGA({
            v: "v1",
            id: randomString(),
            connectorId: connector.connectorId,
            checksum: TextPart,
            stakeKey: stakeKey,
            type: "connector",
            online: true,
          })
        )
        setConnectorLoading("")
      })
      .catch((error: any) => {
        console.log("connector error ::", error)
        setConnectorLoading("")
      })
    setConnectorLoading("")
  }

  // TODO: hw ledger
  const connectLedgerWallet = () => {
    console.log("connectLedgerWallet")
  }

  // TODO: hw trezor
  const connectTrezorWallet = () => {
    console.log("connectTrezorWallet")
  }

  const connectReadOnlyWallet = (values: any) => {
    const { input } = values
    const processAdding = (stakeKey: string) => {
      const { TextPart } = walletChecksum(stakeKey)
      dispatch(
        AccountActions.ACCOUNT_ADD_SAGA({
          v: "v1",
          id: randomString(),
          connectorId: "",
          checksum: TextPart,
          stakeKey,
          type: "readonly",
          online: false,
        })
      )
    }
    // TODO: check validity stake key and address by CSL
    if (input.startsWith("stake1") || input.startsWith("stake_test1")) {
      processAdding(input)
    } else {
      try {
        const { words } = bech32.decode(input, 1000)
        const stakeKeyHex = `${config.network.id === 1 ? "e1" : "e0"}${Buffer.from(bech32.fromWords(words)).toString("hex").slice(-56)}`
        const stakeKeyBech32 = bech32.encode(config.network.id === 1 ? "stake" : "stake_test", bech32.toWords(Buffer.from(stakeKeyHex, "hex")))
        processAdding(stakeKeyBech32)
      } catch {
        message.error("Address has no Stake Key")
      }
    }
    form.resetFields()
  }

  const connectorsAvailable = getConnectors()
  const connectorsInstalled: IConnector[] = connectors.map((connector: IConnector) => {
    return {
      ...connector,
      installed: connectorsAvailable.includes(connector.connectorId),
    }
  })

  return (
    <div>
      <div className="mb-4 pb-1" hidden={accountsList.length < 1}>
        {/* <div onClick={() => {
          dispatch(AccountActions.ACCOUNT_CURRENT_SET(null))
        }}>
          Set Guest
        </div> */}
        {accountsList.map((account, i) => {
          const isAdaHandleEnabled = !adaHandleDisabled[account?.id || ""] && adaHandle?.[account?.id || ""]
          return (
            <div className="ray-connect" key={i}>
              <div
                className={`ray-connect-item ${account.id === accountCurrent?.id ? "ray-connect-item-active" : ""}`}
                onClick={() => dispatch(AccountActions.ACCOUNT_CURRENT_SET(account))}
              >
                <span className="ray-connect-item-check">
                  <span />
                  <i className="ri ri-check" />
                </span>
                <span className="ray-connect-item-img">
                  <AccountAvatar account={account} tooltip={false} dot={false} action={false} />
                </span>
                <span className="ray-connect-item-title">
                  <div>
                    <strong className="me-1">
                      {isAdaHandleEnabled && (
                        <span style={{ position: "relative", top: "-2px" }}>
                          <span className="ray-handle-icon">
                            <SVGAdaHandle />
                          </span>
                          <span className="ray-handle-title">{adaHandle?.[account?.id || ""]?.assetNameAscii}</span>
                        </span>
                      )}
                      {!isAdaHandleEnabled && account?.checksum}
                    </strong>
                    <strong className="text-nowrap">
                      <sup className="d-inline-flex align-items-center justify-items-center">
                        {account.type === "hw" && (
                          <>
                            <span>{account.connectorId}</span>
                            <span className="ray-dot ray-dot-blue ms-1 me-1" />
                            <span>hardware</span>
                          </>
                        )}
                        {account.type === "readonly" && <span>{account.type}</span>}
                        {account.type === "connector" && (
                          <>
                            <span>{account.connectorId}</span>
                            {account.online && (
                              <>
                                <span className="ray-dot ms-1 me-1" />
                                <span>online</span>
                              </>
                            )}
                            {!account.online && <span className="ray-dot ray-dot-gray ms-1 me-1" />}
                          </>
                        )}
                      </sup>
                    </strong>
                  </div>
                  <div className="font-size-12 text-muted-mono">{truncate(account.stakeKey, 9, 4)}</div>
                </span>
                <span className="ray-connect-item-status">
                  <i className="ri ri-arrow_forward me-1" />
                </span>
              </div>
              {/* <Tooltip title="Remove"> */}
              <Popconfirm
                placement="topRight"
                title="Erase wallet data from this device?"
                onConfirm={() => {
                  dispatch(AccountActions.ACCOUNT_REMOVE_SAGA(account))
                }}
                okText="Yes"
                cancelText="No"
              >
                <div className="ray-connect-item ray-connect-item-control">
                  <i className="ri ri-trash" />
                </div>
              </Popconfirm>
              {/* </Tooltip> */}
            </div>
          )
        })}
        <div className="ray-line mt-4 pt-3 mb-0" />
      </div>
      <div className="pwa-d-none">
        <div className="mb-4 pb-1">
          <div className="mb-3">
            <span className="me-3">
              <strong>Connect new wallet</strong>
            </span>
            <span>
              <Radio.Group defaultValue={connectorFilter} onChange={(e) => setConnectorFilter(e.target.value)}>
                <Radio value="popular">Popular</Radio>
                <Radio value="all">All</Radio>
              </Radio.Group>
            </span>
          </div>
          {connectorsInstalled
            .filter((connector) => (connectorFilter === "all" ? connector : connector.tags.includes(connectorFilter)))
            .map((connector, i) => {
              return (
                <div className="ray-connect" key={i}>
                  <div
                    className={`ray-connect-item ${
                      !connector.installed || !connector.active ? "ray-connect-item-inactive" : ""
                    }
                    }`}
                    onClick={() => connectConnector(connector)}
                  >
                    <span className="ray-connect-item-img">
                      <img src={`/resources/wallets/${connector.img}`} alt={connector.title} />
                    </span>
                    <span className="ray-connect-item-title">
                      <strong className="d-flex align-items-center justify-items-center">
                        <span>{connector.title}</span>
                      </strong>
                    </span>
                    <span className="ray-connect-item-status">
                      {connector.active && connector.installed && (
                        <>
                          {connectorLoading !== connector.connectorId && <i className="ri ri-add me-1" />}
                          {connectorLoading === connector.connectorId && <i className="ray-spinner" />}
                        </>
                      )}
                      {connector.active && !connector.installed && <span>Not Installed</span>}
                      {!connector.active && <span>Soon</span>}
                    </span>
                  </div>
                </div>
              )
            })}
          <div className="ray-connect" hidden={connectorFilter === "popular"}>
            <div className="ray-connect-item ray-connect-item-inactive" onClick={() => connectLedgerWallet()}>
              <span className="ray-connect-item-img">
                <img src="/resources/wallets/ledger.svg" alt="Ledger" className="ray-on-dark-inverse" />
              </span>
              <span className="ray-connect-item-title">
                <strong className="d-flex align-items-center justify-items-center">Ledger</strong>
              </span>
              <span className="ray-connect-item-status">
                <span>Soon</span>
              </span>
            </div>
          </div>
          <div className="ray-connect" hidden={connectorFilter === "popular"}>
            <div className="ray-connect-item ray-connect-item-inactive" onClick={() => connectTrezorWallet()}>
              <span className="ray-connect-item-img">
                <img src="/resources/wallets/trezor.svg" alt="Ledger" className="ray-on-dark-inverse" />
              </span>
              <span className="ray-connect-item-title">
                <strong className="d-flex align-items-center justify-items-center">Trezor</strong>
              </span>
              <span className="ray-connect-item-status">
                <span>Soon</span>
              </span>
            </div>
          </div>
        </div>
        <div className="ray-line mt-4 mb-4 pt-1 pb-1" />
      </div>
      <div className="mb-5">
        <div className="mb-3">
          <strong>Or connect read-only wallet</strong>
        </div>
        <Form form={form} layout="inline" requiredMark={false} onFinish={connectReadOnlyWallet} preserve={false}>
          <Form.Item
            name="input"
            className="flex-grow-1 mb-0"
            rules={[
              {
                required: true,
                message: "Address or Stake Key is required",
              },
              () => ({
                // TODO: check validity stake key and address by CSL
                validator(_, value) {
                  if (
                    !value ||
                    (value.startsWith("addr1") && value.length === 103) ||
                    (value.startsWith("stake1") && value.length === 59) ||
                    (value.startsWith("addr_test1") && value.length === 108) ||
                    (value.startsWith("stake_test1") && value.length === 64)
                  ) {
                    return Promise.resolve()
                  }
                  return Promise.reject(new Error("Address or Stake Key is wrong"))
                },
              }),
            ]}
            hasFeedback
          >
            <Input size="large" autoComplete="off" placeholder="Enter addr1... or stake1..." />
          </Form.Item>
          <Form.Item className="me-0">
            <Button htmlType="submit" size="large" type="primary" className="ray-btn ray-btn-outline">
              <i className="ri ri-add me-2" /> Connect
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="font-size-14 text-muted">
        Powered with
        <span className={style.cardano}>
          <SVGCardano />
          <strong>Cardano</strong>
        </span>
      </div>
    </div>
  )
}

export default ModalWalletConnect
