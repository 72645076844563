import React, { useEffect, useState } from "react"
import { Modal, Button, Tabs } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"
import Pending from "./Pending"
import Success from "./Success"
// import Add from "./Add"
// import Withdraw from "./Withdraw"
import Create from "./Create"
import * as style from "./style.module.scss"

const ModalAdaTx = () => {
  const dispatch = useAppDispatch()
  const modalTokensTx = useAppSelector((state) => state.transaction.modalTokensTx)
  const pendingTxs = useAppSelector((state) => state.transaction.pendingTxs)
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)

  const pending = !!pendingTxs?.[accountCurrent?.id || ""]
  const success = !!pendingTxs?.[accountCurrent?.id || ""]?.sent

  return (
    <Modal
      title={null}
      open={modalTokensTx.open}
      onCancel={() => dispatch(TransactionActions.MODAL_TOKENS_TRANSACTION_SET({ open: false, type: "" }))}
      footer={null}
      width={modalTokensTx.type === "create" ? 650 : 650} // 440}
      closeIcon={<span className="ri ri-close" />}
      destroyOnClose
    >
      {/* {!pending && !success && modalTokensTx.type === "add" && <Add />} */}
      {/* {!pending && !success && modalTokensTx.type === "withdraw" && <Withdraw />} */}
      {!pending && !success && modalTokensTx.type === "create" && <Create />}
      {pending && !success && <Pending />}
      {success && <Success />}
    </Modal>
  )
}

export default ModalAdaTx
