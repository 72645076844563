import { AdaHandleTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.adaHandle."
const initialState: AdaHandleTypes.ISettings = {
  current: {},
  disabled: {},
  list: {},
}

export default (
  state: AdaHandleTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: AdaHandleTypes.Actions
): AdaHandleTypes.ISettings => {
  switch (action.type) {
    case AdaHandleTypes.Enum.CLEAR:
      return initialState

    case AdaHandleTypes.Enum.ADA_HANDLE_CURRENT_SET:
      setPersistSetting(persistStorePrefix, "current", action.current)
      return { ...state, current: action.current }

    case AdaHandleTypes.Enum.ADA_HANDLE_DISABLED_SET:
      setPersistSetting(persistStorePrefix, "disabled", action.disabled)
      return { ...state, disabled: action.disabled }

    case AdaHandleTypes.Enum.ADA_HANDLE_LIST_SET:
      setPersistSetting(persistStorePrefix, "list", action.list)
      return { ...state, list: action.list }

    default:
      return state
  }
}
