// extracted by mini-css-extract-plugin
export var connect = "style-module--connect--2cc8a";
export var logo = "style-module--logo--c0b73";
export var rotation = "style-module--rotation--50733";
export var sidebar = "style-module--sidebar--d4277";
export var sidebarBottom = "style-module--sidebarBottom--20f1f";
export var statusDot = "style-module--statusDot--40226";
export var statusDotAnimate = "style-module--statusDotAnimate--76e4f";
export var statusDotBlue = "style-module--statusDotBlue--f3a01";
export var statusDotGreen = "style-module--statusDotGreen--de4b9";
export var wallets = "style-module--wallets--afc18";