import React, { useEffect, useState } from "react"
import { Button, Tabs, message } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"
import * as Connector from "@/services/connectors"
import TokensBreakdown from "@/components/common/TokensBreakdown"
import * as Web3 from "@/services/cardano-web3-js"
import Preload from "../Preload"
import Error from "../Error"
import LinkCardanoscan from "@/components/common/LinkCardanoscan"

const Delegate = () => {
  const dispatch = useAppDispatch()
  const balanceAddresses = useAppSelector((state) => state.account.balanceAddresses)
  const accountInfo = useAppSelector((state) => state.account.accountInfo)
  const balanceUtxos = useAppSelector((state) => state.account.balanceUtxos)
  const tip = useAppSelector((state) => state.network.tip)
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)
  const modalAdaTx = useAppSelector((state) => state.transaction.modalAdaTx)

  const [preload, setPreload] = useState(true)
  const [loading, setLoading] = useState(false)
  const [txFee, setTxFee] = useState("")
  const [txUnsigned, setTxUnsigned] = useState("")
  const [txError, setTxError] = useState("")

  const isRegistration = !accountInfo?.delegated_pool

  useEffect(() => {
    setTimeout(() => {
      prepareDelegation()
    }, 1000)
  }, [])

  const prepareDelegation = async () => {
    try {
      console.log(        balanceUtxos,
        accountInfo?.stake_address,
        balanceAddresses[0],
        tip?.abs_slot,
        modalAdaTx?.poolId,
        isRegistration)
      const tx = await Web3.delegateTx(
        balanceUtxos,
        accountInfo?.stake_address,
        balanceAddresses[0],
        tip?.abs_slot,
        modalAdaTx?.poolId,
        isRegistration
      )
      setTxUnsigned(tx.txCbor)
      setTxFee(tx.fee)
    } catch (e: any) {
      onError(e)
    }
    setPreload(false)
  }

  const submitTx = async () => {
    try {
      setLoading(true)
      const txWitness = await Connector.signTx(accountCurrent?.connectorId || "", txUnsigned)
      const txSigned = await Web3.addWitnessToTx(txWitness, txUnsigned)
      try {
        const submitTx = await Connector.submitTx(accountCurrent?.connectorId || "", txSigned)
        if (submitTx) {
          dispatch(TransactionActions.PENDING_TX_UPDATE_SAGA(submitTx))
        }
      } catch (e: any) {
        onError(e)
      }
    } catch (e: any) {
      onError(e)
    }
    setLoading(false)
  }

  const onError = (e: any) => {
    console.log(e)
    const type = typeof e
    switch (type) {
      case "string":
        setTxError(e)
        break
      case "object":
        if (e?.code && e.info) {
          setTxError(e.info)
        } else if (e?.message) {
          setTxError(e.message)
        } else {
          setTxError("Something went wrong, try again...")
        }
        break
      default:
        setTxError("Something went wrong, try again...")
    }
  }

  return (
    <div>
      {txError && <Error text={txError} />}
      {preload && !txError && <Preload />}
      {!preload && !txError && (
        <div className="ray-tx">
          <div className="ray-tx-head">
            <div className="ray-tx-head-icon">
              <i className="ri ri-upload text-success" />
            </div>
            <div className="ray-tx-head-title">Delegate ADA To</div>
            <div className="ray-tx-head-ticker">{modalAdaTx?.poolTicker || "—"}</div>
            <div className="text-center text-muted mt-0">
              <LinkCardanoscan
                title={modalAdaTx?.poolId || "......"}
                link={modalAdaTx?.poolId || "......"}
                type="transaction"
              />
            </div>
          </div>
          <div className="ray-line mt-0" />
          {isRegistration && (
            <div className="ray-tx-list">
              <div className="mb-3">
                <TokensBreakdown
                  items={[
                    {
                      type: "simple",
                      title: "Certificate Deposit",
                      ticker: "ADA",
                      quantity: "2000000",
                      decimals: 6,
                    },
                    {
                      type: "simple",
                      title: "Tx Fee",
                      ticker: "ADA",
                      quantity: txFee || "0",
                      decimals: 6,
                    },
                  ]}
                />
              </div>
              <div className="mb-5">
                <TokensBreakdown
                  items={[
                    {
                      type: "simple",
                      title: "Total",
                      ticker: "ADA",
                      quantity: isRegistration ? (Number(txFee) + 2000000).toString() : txFee,
                      decimals: 6,
                    },
                  ]}
                />
              </div>
            </div>
          )}
          {!isRegistration && (
            <div className="ray-tx-list">
              <div className="mb-4 pb-3">
                <TokensBreakdown
                  items={[
                    {
                      type: "simple",
                      title: "Tx Fee",
                      ticker: "ADA",
                      quantity: isRegistration ? (Number(txFee) + 2000000).toString() : txFee,
                      decimals: 6,
                    },
                  ]}
                />
              </div>
            </div>
          )}
          <div className="ray-tx-btns pb-3">
            <Button className="ray-btn ray-btn-blue w-100p" size="large" onClick={submitTx} loading={loading}>
              <i className="ri ri-upload me-2" />
              <span>Delegate</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Delegate
