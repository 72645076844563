/**
 * Settings Types
 */

export type Theme = "default" | "dark"
export type Currency = "usd" | "eur" | "jpy" | "cny"
export type UIScale = "small" | "medium" | "large" | "xlarge"
export type AdaDefaultPage = "/" | "/premium/" | "/bookmarks/" | "/my-position/"
export type TokenDefaultPage = "/" | "/premium/" | "/bookmarks/" | "/my-position/" | "/txs/"
export type AdaView = "table" | "card"
export type TokensView = "table" | "card"
export interface IHideBalance {
  [key: string]: boolean
}

export interface ISettings {
  theme: Theme
  cookiesViewed: boolean
  currency: Currency
  uiScale: UIScale
  adaDefaultPage: AdaDefaultPage
  tokensDefaultPage: TokenDefaultPage
  hideBalance: IHideBalance
  adaBookmarks: string[]
  tokensBookmarks: string[]
  adaView: AdaView
  tokensView: TokensView
}

/**
 * Settings Actions
 */

export enum Enum {
  INIT = "settings/INIT",
  COOKIES_SET = "settings/COOKIES_SET",
  THEME_CHANGE = "settings/THEME_CHANGE",
  THEME_CHANGE_SAGA = "settings/THEME_CHANGE_SAGA",
  CURRENCY_SET = "settings/CURRENCY_SET",
  UI_SCALE_SET = "settings/UI_SCALE_SET",
  UI_SCALE_UPDATE_SAGA = "settings/UI_SCALE_UPDATE_SAGA",
  ADA_DEFAULT_PAGE_SET = "settings/ADA_DEFAULT_PAGE_SET",
  TOKENS_DEFAULT_PAGE_SET = "settings/TOKENS_DEFAULT_PAGE_SET",
  HIDE_BALANCE_SET = "settings/HIDE_BALANCE_SET",
  ADA_BOOKMARKS_UPDATE = "settings/ADA_BOOKMARKS_UPDATE",
  TOKENS_BOOKMARKS_UPDATE = "settings/TOKENS_BOOKMARKS_UPDATE",
  ADA_VIEW_SET = "settings/ADA_VIEW_SET",
  TOKENS_VIEW_SET = "settings/TOKENS_VIEW_SET",
}

export type AInit = {
  type: typeof Enum.INIT
}

export type ACookiesSet = {
  type: typeof Enum.COOKIES_SET
  cookiesViewed: boolean
}

export type AThemeChange = {
  type: typeof Enum.THEME_CHANGE
  theme: Theme
}

export type AThemeChangeSaga = {
  type: typeof Enum.THEME_CHANGE_SAGA
  theme: Theme
}

export type ACurrencySet = {
  type: typeof Enum.CURRENCY_SET
  currency: Currency
}

export type AUIScaleSet = {
  type: typeof Enum.UI_SCALE_SET
  uiScale: UIScale
}

export type AUIScaleUpdateSaga = {
  type: typeof Enum.UI_SCALE_UPDATE_SAGA
  uiScale: UIScale
}

export type AAdaDefaultPageSet = {
  type: typeof Enum.ADA_DEFAULT_PAGE_SET
  adaDefaultPage: AdaDefaultPage
}

export type ATokensDefaultPageSet = {
  type: typeof Enum.TOKENS_DEFAULT_PAGE_SET
  tokensDefaultPage: TokenDefaultPage
}

export type AHideBalanceSet = {
  type: typeof Enum.HIDE_BALANCE_SET
  hideBalance: IHideBalance
}

export type ATokensBookmarksUpdate = {
  type: typeof Enum.TOKENS_BOOKMARKS_UPDATE
  poolId: string
}

export type AAdaBookmarksUpdate = {
  type: typeof Enum.ADA_BOOKMARKS_UPDATE
  poolId: string
}

export type AAdaViewSet = {
  type: typeof Enum.ADA_VIEW_SET
  adaView: AdaView
}

export type ATokensViewSet = {
  type: typeof Enum.TOKENS_VIEW_SET
  tokensView: TokensView
}

export type Actions =
  | AInit
  | ACookiesSet
  | AThemeChange
  | AThemeChangeSaga
  | ACurrencySet
  | AUIScaleSet
  | AUIScaleUpdateSaga
  | AAdaDefaultPageSet
  | ATokensDefaultPageSet
  | AHideBalanceSet
  | ATokensBookmarksUpdate
  | AAdaBookmarksUpdate
  | AAdaViewSet
  | ATokensViewSet
