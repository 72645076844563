import { all, takeEvery, put, call, select, cancelled, takeLatest } from "redux-saga/effects"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import Coingecko from "@/services/coingecko"
import type { RootState } from "../provider"
import { NetworkActions, NetworkTypes } from "./"

export function* NETWORK_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const networkTip: {
      success?: AxiosResponse<KoiosTypes.TipResponse>
      error?: AxiosError
    } = yield call(Koios.Tip, undefined, undefined, abortController.signal)
    if (networkTip?.success) {
      const networkTipData = networkTip?.success?.data?.[0] || null
      yield put(NetworkActions.TIP_SET(networkTipData))
      const networkEpochCurrent: NetworkTypes.EpochCurrent = yield select(
        (state: RootState) => state.network.epochCurrent
      )
      if (networkEpochCurrent?.epoch_no !== networkTipData.epoch_no) {
        const epochInformation: {
          success?: AxiosResponse<KoiosTypes.EpochInfoResponse>
          error?: AxiosError
        } = yield call(
          Koios.EpochInfo,
          { _epoch_no: networkTipData?.epoch_no.toString() },
          undefined,
          undefined,
          abortController.signal
        )
        if (epochInformation?.success) {
          const epochInformationData = epochInformation?.success?.data?.[0] || null
          yield put(NetworkActions.EPOCH_CURRENT_SET(epochInformationData))
        }
        if (epochInformation?.error) {
          yield console.log("epochInformation :: error")
        }
      }
    }
    if (networkTip?.error) {
      yield console.log("networkTip :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export function* EXCHANGE_RATES_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const exchangeRates: {
      success?: AxiosResponse<NetworkTypes.IExchangeRates>
      error?: AxiosError
    } = yield call(
      Coingecko.GetRawUrl,
      "/simple/price?ids=bitcoin,cardano,ray-network,ethereum&vs_currencies=USD,EUR,JPY,CNY",
      abortController.signal
    )
    if (exchangeRates?.success) {
      const exchangeRatesData = exchangeRates?.success?.data || {}
      yield put(NetworkActions.EXCHANGE_RATES_SET(exchangeRatesData))
    }
    if (exchangeRates?.error) {
      yield console.log("exchangeRates :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(NetworkTypes.Enum.NETWORK_UPDATE_SAGA, NETWORK_UPDATE_SAGA),
    takeEvery(NetworkTypes.Enum.EXCHANGE_RATES_UPDATE_SAGA, EXCHANGE_RATES_UPDATE_SAGA),
  ])
}
