import { AdaHandleTypes } from "./"

export function CLEAR(): AdaHandleTypes.AClear {
  return { type: AdaHandleTypes.Enum.CLEAR }
}

export function ADA_HANDLE_CURRENT_SET(current: AdaHandleTypes.IAdaHandleCurrent): AdaHandleTypes.AAdaHandleCurrentSet {
  return { type: AdaHandleTypes.Enum.ADA_HANDLE_CURRENT_SET, current }
}

export function ADA_HANDLE_DISABLED_SET(
  disabled: AdaHandleTypes.IAdaHandleDisabled
): AdaHandleTypes.AAdaHandleDisabledSet {
  return { type: AdaHandleTypes.Enum.ADA_HANDLE_DISABLED_SET, disabled }
}

export function ADA_HANDLE_LIST_SET(list: AdaHandleTypes.IAdaHandleList): AdaHandleTypes.AAdaHandleListSet {
  return { type: AdaHandleTypes.Enum.ADA_HANDLE_LIST_SET, list }
}

export function ADA_HANDLE_UPDATE_SAGA(list: AdaHandleTypes.ITokenRaw[]): AdaHandleTypes.AAdaHandleUpdateSaga {
  return { type: AdaHandleTypes.Enum.ADA_HANDLE_UPDATE_SAGA, list }
}
