import { all, takeEvery, put, call, select, takeLatest } from "redux-saga/effects"
import type { RootState } from "../provider"
import { SettingsActions, SettingsTypes } from "./"
import { AccountActions, AccountTypes } from "@/redux/account"

export function* THEME_CHANGE_SAGA({ theme }: SettingsTypes.AThemeChangeSaga) {
  const html = global.document.querySelector("html")!
  html.setAttribute("data-disable-transitions", "true")
  html.setAttribute("data-theme", theme)
  setTimeout(() => {
    html.removeAttribute("data-disable-transitions")
  }, 500)
  yield put(SettingsActions.THEME_CHANGE(theme))
}

export function* UI_SCALE_UPDATE_SAGA({ uiScale }: SettingsTypes.AUIScaleSet) {
  const html = global.document.querySelector("html")!
  html.setAttribute("data-disable-transitions", "true")
  html.setAttribute("data-scale", uiScale)
  setTimeout(() => {
    html.removeAttribute("data-disable-transitions")
  }, 500)
  yield put(SettingsActions.UI_SCALE_SET(uiScale))
}

export function* INIT() {
  const theme: SettingsTypes.Theme = yield select((state: RootState) => state.settings.theme)
  yield put(SettingsActions.THEME_CHANGE_SAGA(theme))

  const uiScale: SettingsTypes.UIScale = yield select((state: RootState) => state.settings.uiScale)
  yield put(SettingsActions.UI_SCALE_UPDATE_SAGA(uiScale))
}

export default function* rootSaga() {
  yield all([
    takeLatest(SettingsTypes.Enum.INIT, INIT),
    takeLatest(SettingsTypes.Enum.THEME_CHANGE_SAGA, THEME_CHANGE_SAGA),
    takeLatest(SettingsTypes.Enum.UI_SCALE_UPDATE_SAGA, UI_SCALE_UPDATE_SAGA),
  ])
}
