import { AccountTypes } from "./"

export function INIT(): AccountTypes.AInit {
  return { type: AccountTypes.Enum.INIT }
}

export function CLEAR(): AccountTypes.AClear {
  return { type: AccountTypes.Enum.CLEAR }
}

export function MODAL_ACCOUNT_SET(modalAccount: AccountTypes.ModalAccount): AccountTypes.AModalAccountSet {
  return { type: AccountTypes.Enum.MODAL_ACCOUNT_SET, modalAccount }
}

export function ACCOUNT_INFO_UPDATE_SAGA(
  loadingType: AccountTypes.AccountInfoLoading,
  clear?: boolean
): AccountTypes.AAccountInfoUpdateSaga {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_UPDATE_SAGA, loadingType, clear }
}

export function ACCOUNT_INFO_CLEAR(): AccountTypes.AAccountInfoClear {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_CLEAR }
}

export function ACCOUNT_INFO_REQUEST(loadingType: AccountTypes.AccountInfoLoading): AccountTypes.AAccountInfoRequest {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_REQUEST, loadingType }
}

export function ACCOUNT_INFO_FAILURE(): AccountTypes.AAccountInfoFailure {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_FAILURE }
}

export function ACCOUNT_INFO_SUCCESS(
  accountInfo: AccountTypes.AccountInfo,
  poolInfo?: AccountTypes.PoolInfo
): AccountTypes.AAccountInfoSuccess {
  return { type: AccountTypes.Enum.ACCOUNT_INFO_SUCCESS, accountInfo, poolInfo }
}

export function BALANCE_UPDATE_SAGA(clear?: boolean): AccountTypes.ABalanceUpdateSaga {
  return { type: AccountTypes.Enum.BALANCE_UPDATE_SAGA, clear }
}

export function BALANCE_CLEAR(): AccountTypes.ABalanceClear {
  return { type: AccountTypes.Enum.BALANCE_CLEAR }
}

export function BALANCE_REQUEST(): AccountTypes.ABalanceRequest {
  return { type: AccountTypes.Enum.BALANCE_REQUEST }
}

export function BALANCE_FAILURE(): AccountTypes.ABalanceFailure {
  return { type: AccountTypes.Enum.BALANCE_FAILURE }
}

export function BALANCE_SUCCESS(
  balanceAda: string | undefined,
  balanceTokens: AccountTypes.BalanceToken[],
  balanceUtxos: AccountTypes.BalanceUtxo[],
  balanceAddresses: string[]
): AccountTypes.ABalanceSuccess {
  return { type: AccountTypes.Enum.BALANCE_SUCCESS, balanceAda, balanceTokens, balanceUtxos, balanceAddresses }
}

export function ACCOUNT_CURRENT_SET(account: AccountTypes.Account | null): AccountTypes.AAccountCurrentSet {
  return { type: AccountTypes.Enum.ACCOUNT_CURRENT_SET, account }
}

export function ACCOUNTS_LIST_SET(accountsList: AccountTypes.Account[]): AccountTypes.AAccountsListSet {
  return { type: AccountTypes.Enum.ACCOUNTS_LIST_SET, accountsList }
}

export function ACCOUNT_ADD_SAGA(account: AccountTypes.Account): AccountTypes.AAccountAddSaga {
  return { type: AccountTypes.Enum.ACCOUNT_ADD_SAGA, account }
}

export function ACCOUNT_REMOVE_SAGA(account: AccountTypes.Account): AccountTypes.AAccountRemoveSaga {
  return { type: AccountTypes.Enum.ACCOUNT_REMOVE_SAGA, account }
}

export function CONNECTORS_UPDATE_STATUS_SAGA(): AccountTypes.AConnectorsUpdateStatusSaga {
  return { type: AccountTypes.Enum.CONNECTORS_UPDATE_STATUS_SAGA }
}
