exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ada-staking-bookmarks-tsx": () => import("./../../../src/pages/ada-staking/bookmarks.tsx" /* webpackChunkName: "component---src-pages-ada-staking-bookmarks-tsx" */),
  "component---src-pages-ada-staking-index-tsx": () => import("./../../../src/pages/ada-staking/index.tsx" /* webpackChunkName: "component---src-pages-ada-staking-index-tsx" */),
  "component---src-pages-ada-staking-my-position-tsx": () => import("./../../../src/pages/ada-staking/my-position.tsx" /* webpackChunkName: "component---src-pages-ada-staking-my-position-tsx" */),
  "component---src-pages-ada-staking-pool-tsx": () => import("./../../../src/pages/ada-staking/pool.tsx" /* webpackChunkName: "component---src-pages-ada-staking-pool-tsx" */),
  "component---src-pages-ada-staking-premium-tsx": () => import("./../../../src/pages/ada-staking/premium.tsx" /* webpackChunkName: "component---src-pages-ada-staking-premium-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../../../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tokens-staking-bookmarks-tsx": () => import("./../../../src/pages/tokens-staking/bookmarks.tsx" /* webpackChunkName: "component---src-pages-tokens-staking-bookmarks-tsx" */),
  "component---src-pages-tokens-staking-index-tsx": () => import("./../../../src/pages/tokens-staking/index.tsx" /* webpackChunkName: "component---src-pages-tokens-staking-index-tsx" */),
  "component---src-pages-tokens-staking-my-position-tsx": () => import("./../../../src/pages/tokens-staking/my-position.tsx" /* webpackChunkName: "component---src-pages-tokens-staking-my-position-tsx" */),
  "component---src-pages-tokens-staking-orders-tsx": () => import("./../../../src/pages/tokens-staking/orders.tsx" /* webpackChunkName: "component---src-pages-tokens-staking-orders-tsx" */),
  "component---src-pages-tokens-staking-pool-tsx": () => import("./../../../src/pages/tokens-staking/pool.tsx" /* webpackChunkName: "component---src-pages-tokens-staking-pool-tsx" */),
  "component---src-pages-tokens-staking-premium-tsx": () => import("./../../../src/pages/tokens-staking/premium.tsx" /* webpackChunkName: "component---src-pages-tokens-staking-premium-tsx" */)
}

