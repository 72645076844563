import React, { useEffect, useState } from "react"
import { Modal, Button, Select, Input, Space, Tooltip, message, Row, Col, Popconfirm, Tabs, Switch } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import Jazzicon from "react-jazzicon"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { SVGAdaHandle } from "@/svg"
import { truncate, numberFromString, randomString } from "@/utils/utils"
import * as style from "./style.module.scss"
import { AccountActions, AccountTypes } from "@/redux/account"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"
import Copy from "@/components/common/Copy"
import { AdaHandleActions } from "@/redux/adaHandle"

const ModalWalletSettings = () => {
  const dispatch = useAppDispatch()
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)
  const accountInfo = useAppSelector((state) => state.account.accountInfo)
  const appTheme = useAppSelector((state) => state.settings.theme)
  const appCurrency = useAppSelector((state) => state.settings.currency)
  const adaHandle = useAppSelector((state) => state.adaHandle.current)
  const adaHandleDisabled = useAppSelector((state) => state.adaHandle.disabled)
  const adaHandleList = useAppSelector((state) => state.adaHandle.list)
  const hideBalance = useAppSelector((state) => state.settings.hideBalance)
  const tokensDefaultPage = useAppSelector((state) => state.settings.tokensDefaultPage)
  const adaDefaultPage = useAppSelector((state) => state.settings.adaDefaultPage)
  const uiScale = useAppSelector((state) => state.settings.uiScale)

  const [typeKey, setTypeKey] = useState("account")

  const isAdaHandleEnabled = !adaHandleDisabled[accountCurrent?.id || ""]
  const isHideBalance = hideBalance[accountCurrent?.id || ""] || false

  const switchAdaHandle = (checked: boolean) => {
    dispatch(
      AdaHandleActions.ADA_HANDLE_DISABLED_SET({
        ...adaHandleDisabled,
        [accountCurrent?.id || ""]: !checked,
      })
    )
  }

  const deregisterStakeKey = () => {
    console.log("deregisterStakeKey")
    dispatch(TransactionActions.MODAL_ADA_TRANSACTION_SET({ open: true, type: "deregister" }))
  }

  const removeAccount = () => {
    if (accountCurrent) {
      dispatch(AccountActions.ACCOUNT_REMOVE_SAGA(accountCurrent))
      dispatch(AccountActions.MODAL_ACCOUNT_SET({ open: false, type: "" }))
    }
  }

  return (
    <div>
      <div className="mt--3 pt-1 mb-4">
        <Tabs
          className="ray-tabs-compact ray-tabs-empty"
          // destroyInactiveTabPane
          activeKey={typeKey}
          onChange={(key) => setTypeKey(key)}
          items={[
            {
              key: "account",
              label: "Account",
            },
            {
              key: "app",
              label: "App",
            },
          ]}
        />
      </div>
      {typeKey === "account" && (
        <>
          <div className="mb-4">
            <h6 className="d-flex align-items-center">
              <span>Hide Balances</span>
              <span className="ms-auto">
                <Switch
                  checked={isHideBalance}
                  onChange={() => {
                    dispatch(
                      SettingsActions.HIDE_BALANCE_SET({
                        ...hideBalance,
                        [accountCurrent?.id || ""]: !isHideBalance,
                      })
                    )
                  }}
                />
              </span>
            </h6>
          </div>
          <div className="mb-4">
            <h6 className="mb-2 pb-2 d-flex align-items-center">
              <span>AdaHandle</span>
              <span className="ms-auto">
                <Switch checked={isAdaHandleEnabled} onChange={switchAdaHandle} />
              </span>
            </h6>
            <Select
              value={adaHandle[accountCurrent?.id || ""]?.fingerprint || undefined}
              onChange={(value) =>
                dispatch(
                  AdaHandleActions.ADA_HANDLE_CURRENT_SET({
                    ...adaHandle,
                    [accountCurrent?.id || ""]: adaHandleList[accountCurrent?.id || ""].filter(
                      (i) => i.fingerprint === value
                    )[0],
                  })
                )
              }
              className="w-100p"
              size="large"
              placeholder="Handle NFT not found"
              disabled={!adaHandleList?.[accountCurrent?.id || ""]?.length || !isAdaHandleEnabled}
              options={adaHandleList?.[accountCurrent?.id || ""]?.map((i) => {
                return {
                  value: i.fingerprint,
                  label: (
                    <>
                      <span className="ray-handle-icon">
                        <SVGAdaHandle />
                      </span>
                      <span className="ray-handle-title">{i.assetNameAscii}</span>
                    </>
                  ),
                }
              })}
            />
          </div>
          <div className="ray-line mt-4 mb-4 pt-2" />
          <div className="mb-4">
            <h6 className="mb-3">Stake Key</h6>
            <Space.Compact className="w-100p" size="large">
              <Input value={accountCurrent?.stakeKey} />
              <Copy copy={accountCurrent?.stakeKey} title="Copy Stake Key">
                <Button type="default">
                  <i className="ri ri-copy" />
                </Button>
              </Copy>
            </Space.Compact>
          </div>
          <div className="ray-line mt-4 mb-4 pt-2" />
          <div className="mb-2">
            <h6 className="mb-3">Danger Zone</h6>
            <div className={style.danger}>
              {/* <div className={style.dangerItem}>
                <div>
                  <div className="text-mono mb-1">
                    <strong>Account PIN Code</strong>
                  </div>
                  <div className="text-muted lh-12">
                    The PIN code will be requested every time you log into the account
                  </div>
                </div>
                <div className="ms-auto ps-3">
                  <Button size="large" className="ray-btn ray-btn-outline" onClick={deregisterStakeKey}>
                    <i className="ri ri-lock me-2 text-danger" />
                    Set
                  </Button>
                </div>
              </div> */}
              {accountInfo && accountInfo?.delegated_pool && accountCurrent?.type !== "readonly" && (
                <div className={style.dangerItem}>
                  <div>
                    <div className="text-mono mb-1">
                      <strong>Deregister Stake Key</strong>
                    </div>
                    <div className="text-muted lh-12">
                      Returns the 2 ADA deposit and deregisters your certificate. Not necessary if you want to
                      re-delegate ADA to another pool
                    </div>
                  </div>
                  <div className="ms-auto ps-3">
                    <Button size="large" className="ray-btn ray-btn-outline" onClick={deregisterStakeKey}>
                      <i className="ri ri-close me-2 text-danger" />
                      Deregister
                    </Button>
                  </div>
                </div>
              )}
              <div className={style.dangerItem}>
                <div>
                  <div className="text-mono mb-1">
                    <strong>Remove</strong>
                  </div>
                  <div className="text-muted lh-12">Wipe all wallet data from this device</div>
                </div>
                <div className="ms-auto ps-3">
                  <Popconfirm
                    placement="topRight"
                    title="Erase wallet data from this device?"
                    onConfirm={removeAccount}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="large" className="ray-btn ray-btn-outline">
                      <i className="ri ri-trash me-2 text-danger" />
                      Remove
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {typeKey === "app" && (
        <>
          <Row gutter={[20, 0]}>
            <Col span={12}>
              <div className="mb-4">
                <h6 className="mb-3">Theme</h6>
                <Select
                  value={appTheme}
                  onChange={(value) => dispatch(SettingsActions.THEME_CHANGE_SAGA(value))}
                  className="w-100p"
                  size="large"
                  options={[
                    { value: "default", label: "Light" },
                    { value: "dark", label: "Dark" },
                  ]}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-4">
                <h6 className="mb-3">Currency</h6>
                <Select
                  value={appCurrency}
                  onChange={(value) => dispatch(SettingsActions.CURRENCY_SET(value))}
                  className="w-100p"
                  size="large"
                  options={[
                    { value: "usd", label: "USD — $" },
                    { value: "eur", label: "EUR — €" },
                    { value: "jpy", label: "JPY — ¥" },
                    { value: "cny", label: "CNY — ¥" },
                  ]}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-4">
                <h6 className="mb-3">Tokens Default Page</h6>
                <Select
                  value={tokensDefaultPage}
                  onChange={(value) => dispatch(SettingsActions.TOKENS_DEFAULT_PAGE_SET(value))}
                  className="w-100p"
                  size="large"
                  options={[
                    { value: "/", label: "Pools List" },
                    { value: "/premium/", label: "Promoted" },
                    { value: "/bookmarks/", label: "Bookmarks" },
                    { value: "/my-position/", label: "My Position" },
                    { value: "/txs/", label: "TXs" },
                  ]}
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-2">
                <h6 className="mb-3">ADA Default Page</h6>
                <Select
                  value={adaDefaultPage}
                  onChange={(value) => dispatch(SettingsActions.ADA_DEFAULT_PAGE_SET(value))}
                  className="w-100p"
                  size="large"
                  options={[
                    { value: "/", label: "Pools List" },
                    { value: "/premium/", label: "Promoted" },
                    { value: "/bookmarks/", label: "Bookmarks" },
                    { value: "/my-position/", label: "My Position" },
                  ]}
                />
              </div>
            </Col>
          </Row>
          <div className="mb-4">
            <h6 className="mb-3">UI Scale Size</h6>
            <Select
              value={uiScale}
              onChange={(value) => dispatch(SettingsActions.UI_SCALE_UPDATE_SAGA(value))}
              className="w-100p"
              size="large"
              options={[
                { value: "small", label: "70%" },
                { value: "medium", label: "85%" },
                { value: "large", label: "100%" },
                { value: "xlarge", label: "115%" },
              ]}
            />
          </div>
        </>
      )}

      {/* <div className="ray-line mt-4 pt-1 mb-4" /> */}
    </div>
  )
}

export default ModalWalletSettings
