import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import Koios, {
  KoiosExtraRpc,
  KoiosExtraRpcTypes,
  KoiosTypes,
  AxiosError,
  AxiosResponse,
} from "@/services/koios-ts-client"
import RayGraph, { RayGraphTypes } from "@/services/raygraph"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { StakingAdaActions, StakingAdaTypes } from "@/redux/stakingAda"
import { pageSizeToContentRange, contentRangeToTotalResults } from "@/utils/utils"
import config from "@/config/common"
import { AccountTypes } from "@/redux/account"

export function* ACCOUNT_HISTORY_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_HISTORY_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_HISTORY_REQUEST())
    const dataAccountHistory: {
      success?: AxiosResponse<KoiosTypes.AccountHistoryResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountHistory,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountHistory?.success) {
      const __data = dataAccountHistory?.success?.data?.[0]?.history || []
      yield put(StakingAdaActions.ACCOUNT_HISTORY_SUCCESS(__data))
    }
    if (dataAccountHistory?.error) {
      yield put(StakingAdaActions.ACCOUNT_HISTORY_FAILURE())
      yield console.log("dataAccountHistory :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_HISTORY_FAILURE())
    }
  }
}

export function* ACCOUNT_REWARDS_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_REWARDS_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_REWARDS_REQUEST())
    const dataAccountRewards: {
      success?: AxiosResponse<KoiosTypes.AccountRewardsResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountRewards,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountRewards?.success) {
      const __data = dataAccountRewards?.success?.data?.[0]?.rewards || []
      yield put(StakingAdaActions.ACCOUNT_REWARDS_SUCCESS(__data))
    }
    if (dataAccountRewards?.error) {
      yield put(StakingAdaActions.ACCOUNT_REWARDS_FAILURE())
      yield console.log("dataAccountRewards :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_REWARDS_FAILURE())
    }
  }
}

export function* ACCOUNT_UPDATES_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_UPDATES_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_UPDATES_REQUEST())
    const dataAccountUpdates: {
      success?: AxiosResponse<KoiosTypes.AccountUpdatesResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountUpdates,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountUpdates?.success) {
      const __data = dataAccountUpdates?.success?.data?.[0].updates
      yield put(StakingAdaActions.ACCOUNT_UPDATES_SUCCESS(__data))
    }
    if (dataAccountUpdates?.error) {
      yield put(StakingAdaActions.ACCOUNT_UPDATES_FAILURE())
      yield console.log("dataAccountUpdates :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_UPDATES_FAILURE())
    }
  }
}

export function* ACCOUNT_WITHDRAWALS_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)
    if (!accountCurrent) {
      StakingAdaActions.ACCOUNT_WITHDRAWALS_CLEAR()
      return
    }
    yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_REQUEST())
    const dataAccountUpdates: {
      success?: AxiosResponse<KoiosTypes.AccountUpdatesResponse>
      error?: AxiosError
    } = yield call(
      Koios.AccountUpdates,
      { _stake_addresses: [accountCurrent.stakeKey] },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataAccountUpdates?.success) {
      const withdrawalTxs = dataAccountUpdates?.success?.data[0].updates
        .filter((item) => item.action_type === "withdrawal")
        .map((item) => item.tx_hash)
      const dataAccountWithdrawalTxs: {
        success?: AxiosResponse<KoiosTypes.TxInfoResponse>
        error?: AxiosError
      } = yield call(
        Koios.TxInfo,
        { _tx_hashes: withdrawalTxs },
        "select=tx_hash,epoch_no,block_height,tx_timestamp,withdrawals",
        undefined,
        abortController.signal
      )
      if (dataAccountWithdrawalTxs?.success) {
        const __withdrawals = dataAccountWithdrawalTxs?.success?.data?.map((item) => {
          return {
            amount: item.withdrawals[0].amount,
            block_height: item.block_height,
            epoch_no: item.epoch_no,
            tx_hash: item.tx_hash,
            tx_timestamp: item.tx_timestamp,
          }
        })
        yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_SUCCESS(__withdrawals))
      }
      if (dataAccountWithdrawalTxs?.error) {
        yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_FAILURE())
        yield console.log("dataAccountWithdrawalTxs :: error")
      }
    }
    if (dataAccountUpdates?.error) {
      yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_FAILURE())
      yield console.log("dataAccountUpdates :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.ACCOUNT_WITHDRAWALS_FAILURE())
    }
  }
}

export function* PREMIUM_POOLS_UPDATE_SAGA() {
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.PREMIUM_POOLS_REQUEST())
    if (!config.isMainnet) {
      yield put(StakingAdaActions.PREMIUM_POOLS_SUCCESS([]))
      return
    }
    const dataPremiumPools: {
      success?: RayGraphTypes.PoolListResponse
      error?: RayGraphTypes.IError
    } = yield call(RayGraph.premiumPools, {
      signal: abortController.signal,
    })
    if (dataPremiumPools?.success) {
      const __data = dataPremiumPools?.success || []
      yield put(StakingAdaActions.PREMIUM_POOLS_SUCCESS(__data))
    }
    if (dataPremiumPools?.error) {
      yield console.log("dataPoolList :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.PREMIUM_POOLS_FAILURE())
    }
  }
}

export function* POOL_LIST_UPDATE_SAGA({ headers, paramsString }: StakingAdaTypes.APoolListUpdateSaga) {
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_LIST_REQUEST())
    const dataPoolList: {
      headers?: any
      success?: AxiosResponse<KoiosExtraRpcTypes.PoolListResponse>
      error?: AxiosError
    } = yield call(KoiosExtraRpc.PoolExplorer, paramsString, headers, abortController.signal)
    if (dataPoolList?.success) {
      const __data = dataPoolList?.success?.data || []
      const __totalResults = contentRangeToTotalResults(dataPoolList?.success?.headers?.["content-range"] || "")
      yield put(StakingAdaActions.POOL_LIST_SUCCESS(__data, __totalResults))
    }
    if (dataPoolList?.error) {
      yield put(StakingAdaActions.POOL_LIST_CLEAR())
      yield put(StakingAdaActions.POOL_LIST_FAILURE())
      yield console.log("dataPoolList :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_LIST_FAILURE())
    }
  }
}

export function* POOL_LIST_UPDATE_BULK_SAGA({
  poolIds,
  headers,
  paramsString,
}: StakingAdaTypes.APoolListUpdateBulkSaga) {
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_LIST_REQUEST())
    const dataPoolList: {
      headers?: any
      success?: AxiosResponse<KoiosExtraRpcTypes.PoolListResponse>
      error?: AxiosError
    } = yield call(
      KoiosExtraRpc.PoolExplorerBulk,
      {
        _pool_bech32_ids: poolIds,
      },
      paramsString,
      headers,
      abortController.signal
    )
    if (dataPoolList?.success) {
      const __data = dataPoolList?.success?.data || []
      const __totalResults = contentRangeToTotalResults(dataPoolList?.success?.headers?.["content-range"] || "")
      yield put(StakingAdaActions.POOL_LIST_SUCCESS(__data, __totalResults))
    }
    if (dataPoolList?.error) {
      yield put(StakingAdaActions.POOL_LIST_CLEAR())
      yield put(StakingAdaActions.POOL_LIST_FAILURE())
      yield console.log("dataPoolList :: error")
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_LIST_FAILURE())
    }
  }
}

export function* POOL_INFO_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolInfoUpdateSaga) {
  yield put(StakingAdaActions.POOL_INFO_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_INFO_REQUEST())
    const dataPoolInformation: {
      success?: AxiosResponse<KoiosExtraRpcTypes.PoolListResponse>
      error?: AxiosError
    } = yield call(
      KoiosExtraRpc.PoolExplorerBulk,
      {
        _pool_bech32_ids: [poolId],
      },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataPoolInformation?.success) {
      const __dataPoolInformation = dataPoolInformation?.success?.data?.[0]
      yield put(StakingAdaActions.POOL_INFO_SUCCESS(__dataPoolInformation))
    }
    if (dataPoolInformation?.error) {
      yield console.log("dataPoolInformation :: error")
      yield put(StakingAdaActions.POOL_INFO_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_INFO_FAILURE())
    }
  }
}

export function* POOL_RAW_DATA_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolInfoUpdateSaga) {
  yield put(StakingAdaActions.POOL_RAW_DATA_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_RAW_DATA_REQUEST())
    const dataPoolInformation: {
      success?: AxiosResponse<KoiosTypes.PoolInfoResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolInfo,
      {
        _pool_bech32_ids: [poolId],
      },
      undefined,
      undefined,
      abortController.signal
    )
    if (dataPoolInformation?.success) {
      const __dataPoolInformation = dataPoolInformation?.success?.data?.[0]
      yield put(StakingAdaActions.POOL_RAW_DATA_SUCCESS(__dataPoolInformation))
    }
    if (dataPoolInformation?.error) {
      yield console.log("dataPoolInformation :: error")
      yield put(StakingAdaActions.POOL_RAW_DATA_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_RAW_DATA_FAILURE())
    }
  }
}

export function* POOL_BLOCKS_UPDATE_SAGA({ poolId, epochNo }: StakingAdaTypes.APoolBlocksUpdateSaga) {
  yield put(StakingAdaActions.POOL_BLOCKS_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_BLOCKS_REQUEST())
    const dataPoolBlocks: {
      success?: AxiosResponse<KoiosTypes.PoolBlocksResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolBlocks,
      {
        _pool_bech32: poolId,
        _epoch_no: epochNo ? epochNo.toString() : undefined,
      },
      "&order=block_height.desc",
      undefined,
      abortController.signal
    )
    if (dataPoolBlocks?.success) {
      const __dataPoolBlocks = dataPoolBlocks?.success?.data
      yield put(StakingAdaActions.POOL_BLOCKS_SUCCESS(__dataPoolBlocks))
    }
    if (dataPoolBlocks?.error) {
      yield console.log("dataPoolBlocks :: error")
      yield put(StakingAdaActions.POOL_BLOCKS_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_BLOCKS_FAILURE())
    }
  }
}

export function* POOL_HISTORY_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolHistoryUpdateSaga) {
  yield put(StakingAdaActions.POOL_HISTORY_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_HISTORY_REQUEST())
    const dataPoolHistory: {
      success?: AxiosResponse<KoiosTypes.PoolHistoryResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolHistory,
      {
        _pool_bech32: poolId,
      },
      "&order=epoch_no.asc",
      undefined,
      abortController.signal
    )
    if (dataPoolHistory?.success) {
      const __dataPoolHistory = dataPoolHistory?.success?.data
      yield put(StakingAdaActions.POOL_HISTORY_SUCCESS(__dataPoolHistory))
    }
    if (dataPoolHistory?.error) {
      yield console.log("dataPoolHistory :: error")
      yield put(StakingAdaActions.POOL_HISTORY_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_HISTORY_FAILURE())
    }
  }
}

export function* POOL_DELEGATORS_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolDelegatorsUpdateSaga) {
  yield put(StakingAdaActions.POOL_DELEGATORS_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_DELEGATORS_REQUEST())
    const dataPoolDelegators: {
      success?: AxiosResponse<KoiosTypes.PoolDelegatorsResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolDelegators,
      {
        _pool_bech32: poolId,
      },
      "&order=active_epoch_no.desc&limit=30",
      undefined,
      abortController.signal
    )
    if (dataPoolDelegators?.success) {
      const __dataPoolHistory = dataPoolDelegators?.success?.data
      yield put(StakingAdaActions.POOL_DELEGATORS_SUCCESS(__dataPoolHistory))
    }
    if (dataPoolDelegators?.error) {
      yield console.log("dataPoolDelegators :: error")
      yield put(StakingAdaActions.POOL_DELEGATORS_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_DELEGATORS_FAILURE())
    }
  }
}

export function* POOL_UPDATES_UPDATE_SAGA({ poolId }: StakingAdaTypes.APoolUpdatesUpdateSaga) {
  yield put(StakingAdaActions.POOL_UPDATES_CLEAR())
  const abortController = new AbortController()
  try {
    yield put(StakingAdaActions.POOL_UPDATES_REQUEST())
    const dataPoolUpdates: {
      success?: AxiosResponse<KoiosTypes.PoolUpdatesResponse>
      error?: AxiosError
    } = yield call(
      Koios.PoolUpdates,
      { _pool_bech32: poolId },
      undefined, // paramsString: "&order=epoch_no.asc",
      undefined,
      abortController.signal
    )
    if (dataPoolUpdates?.success) {
      const __dataPoolUpdates = dataPoolUpdates?.success?.data
      yield put(StakingAdaActions.POOL_UPDATES_SUCCESS(__dataPoolUpdates))
    }
    if (dataPoolUpdates?.error) {
      yield console.log("dataPoolUpdates :: error")
      yield put(StakingAdaActions.POOL_UPDATES_FAILURE())
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
      yield put(StakingAdaActions.POOL_UPDATES_FAILURE())
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_HISTORY_UPDATE_SAGA, ACCOUNT_HISTORY_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_REWARDS_UPDATE_SAGA, ACCOUNT_REWARDS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_UPDATES_UPDATE_SAGA, ACCOUNT_UPDATES_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_UPDATE_SAGA, ACCOUNT_WITHDRAWALS_UPDATE_SAGA),

    takeLatest(StakingAdaTypes.Enum.PREMIUM_POOLS_UPDATE_SAGA, PREMIUM_POOLS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_LIST_UPDATE_SAGA, POOL_LIST_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_LIST_UPDATE_BULK_SAGA, POOL_LIST_UPDATE_BULK_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_INFO_UPDATE_SAGA, POOL_INFO_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_BLOCKS_UPDATE_SAGA, POOL_BLOCKS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_HISTORY_UPDATE_SAGA, POOL_HISTORY_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_DELEGATORS_UPDATE_SAGA, POOL_DELEGATORS_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_UPDATES_UPDATE_SAGA, POOL_UPDATES_UPDATE_SAGA),
    takeLatest(StakingAdaTypes.Enum.POOL_RAW_DATA_UPDATE_SAGA, POOL_RAW_DATA_UPDATE_SAGA),
  ])
}
