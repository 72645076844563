import React, { useRef, useState, useEffect } from "react"
import * as style from "./style.module.scss"

const HorizontalScroll = ({ children }: { children: any }) => {
  const [showLeft, setShowLeft] = useState(false)
  const [showRight, setShowRight] = useState(false)

  const scrollRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const innerRef = useRef() as React.MutableRefObject<HTMLDivElement>

  const checkAndSetShowRight = (inner: HTMLDivElement, scroll: HTMLDivElement) => {
    if (inner.offsetWidth < scroll.scrollWidth) {
      setShowRight(true)
    } else {
      setShowRight(false)
      setShowLeft(false)
    }
  }

  const updateScroll = (inner: HTMLDivElement, scroll: HTMLDivElement, value: number, smooth = false) => {
    if (inner && scroll) {
      scroll.scrollTo({
        left: scroll.scrollLeft + value,
        behavior: smooth ? "smooth" : "auto",
      })
      setTimeout(
        () => {
          scroll.scrollLeft > 0 ? setShowLeft(true) : setShowLeft(false)
          scroll.scrollLeft >= scroll.scrollWidth - inner.offsetWidth - 1
            ? setShowRight(false)
            : checkAndSetShowRight(inner, scroll)
        },
        smooth ? 200 : 0
      )
    }
  }

  const updateTouch = (inner: HTMLDivElement, scroll: HTMLDivElement) => {
    if (inner && scroll) {
      scroll.scrollLeft > 0 ? setShowLeft(true) : setShowLeft(false)
      scroll.scrollLeft >= scroll.scrollWidth - inner.offsetWidth - 1
        ? setShowRight(false)
        : checkAndSetShowRight(inner, scroll)
    }
  }

  useEffect(() => {
    const scroll = scrollRef.current
    const inner = innerRef.current
    if (scroll && inner) {
      checkAndSetShowRight(inner, scroll)
      const resizeObserver = new ResizeObserver(() => {
        checkAndSetShowRight(inner, scroll)
      })
      resizeObserver.observe(inner)
      const onWheel = (e: WheelEvent) => {
        if (inner.offsetWidth < scroll.scrollWidth) {
          if (e.deltaY !== 0) {
            e.preventDefault()
            updateScroll(inner, scroll, e.deltaY)
          }
        }
      }
      const onTouchMove = (e: TouchEvent) => {
        if (inner.offsetWidth < scroll.scrollWidth) {
          updateTouch(inner, scroll)
        }
      }
      scroll.addEventListener("wheel", onWheel)
      scroll.addEventListener("touchmove", onTouchMove)
      return () => {
        scroll.removeEventListener("wheel", onWheel)
        resizeObserver.disconnect()
      }
    }
  }, [])

  return (
    <div className={style.outer}>
      {showLeft && (
        <div
          className={`${style.arrow} ${style.arrowLeft}`}
          onClick={() => updateScroll(innerRef.current, scrollRef.current, -150, true)}
        >
          <i className="ri ri-chevron_backward" />
        </div>
      )}
      {showRight && (
        <div
          className={`${style.arrow} ${style.arrowRight}`}
          onClick={() => updateScroll(innerRef.current, scrollRef.current, 150, true)}
        >
          <i className="ri ri-chevron_forward" />
        </div>
      )}
      <div ref={scrollRef} className={style.container}>
        <div ref={innerRef}>{children}</div>
      </div>
    </div>
  )
}

export default HorizontalScroll
