import React, { useState } from "react"
import { Table, Tag, Input } from "antd"
import { dateFormatFull, truncate } from "@/utils/utils"
import { AccountTypes } from "@/redux/account"
import { NetworkTypes } from "@/redux/network"
import Informer from "@/components/informers"
import type { ColumnsType } from "antd/es/table"
import LinkCardanoscan from "@/components/common/LinkCardanoscan"
import TokenCached from "@/components/common/TokenCached"
import Token from "@/components/common/Token"
import { CachedTokensTypes } from "@/redux/cachedTokens"

const AccountTokensTable = ({
  data,
  loading,
  border,
  wide = true,
  size = "middle",
}: {
  data: {
    asset: AccountTypes.BalanceToken["asset"]
    asset_info: CachedTokensTypes.AssetInformation | null
    quantity: AccountTypes.BalanceToken["quantity"]
  }[]
  loading?: boolean
  border?: boolean
  wide?: boolean
  size?: "small" | "middle" | "large"
}) => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const columns: ColumnsType<{
    asset: AccountTypes.BalanceToken["asset"]
    asset_info: CachedTokensTypes.AssetInformation | null
    quantity: AccountTypes.BalanceToken["quantity"]
  }> = [
    {
      title: "#",
      key: "number",
      width: "33px",
      render: (record, records, index) => page * pageSize + index - pageSize + 1,
    },
    {
      title: "Name",
      dataIndex: ["asset", "assetNameAscii"],
      key: "token",
      sorter: (a, b) => (a.asset.assetNameAscii || "").localeCompare(b.asset.assetNameAscii || ""),
      render: (record, records) => <TokenCached asset={records.asset} maxWidth={180} disableUpdateCache />,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "25%",
      sorter: (a, b) =>
        Number(a.quantity) / Math.pow(10, a.asset_info?.token_registry_metadata?.decimals || 0) -
        Number(b.quantity) / Math.pow(10, b.asset_info?.token_registry_metadata?.decimals || 0),
      render: (record, records) => (
        <div>
          <Token amount={record} decimals={records.asset_info?.token_registry_metadata?.decimals || 0} small />
        </div>
      ),
    },
    {
      title: "Fingerprint",
      dataIndex: ["asset", "fingerprint"],
      key: "fingerprint",
      width: "25%",
      align: "right",
      sorter: (a, b) => (a.asset.fingerprint || "").localeCompare(b.asset.fingerprint || ""),
      render: (record) => <LinkCardanoscan title={record} truncate={4} link={record} type="token" />,
    },
  ]

  return (
    <div className={`ray-table ${wide ? "ray-table-wide" : ""} ${border ? "ray-table-border" : ""}`}>
      <Table
        rowKey={(i) => i.asset.fingerprint}
        onChange={(pagination) => {
          setPage(pagination.current || 1)
          setPageSize(pagination.pageSize || 10)
        }}
        dataSource={data}
        columns={columns}
        size={size}
        pagination={{
          position: ["bottomLeft"],
          size: "default",
          pageSize: pageSize,
          showSizeChanger: data.length > 10,
          showQuickJumper: false,
          showPrevNextJumpers: false,
        }}
        loading={{
          spinning: !!loading,
          indicator: <i className="ray-spinner" />,
        }}
        locale={{
          emptyText: <div className="py-4 mb-1">No Data</div>,
        }}
      />
    </div>
  )
}

export default AccountTokensTable
