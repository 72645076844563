import { StakingAdaTypes } from "./"

const initialState: StakingAdaTypes.ISettings = {
  premiumPools: [],
  premiumPoolsLoading: false,

  accountHistoryLoading: false,
  accountRewardsLoading: false,
  accountUpdatesLoading: false,
  accountWithdrawalsLoading: false,
  accountHistory: [],
  accountRewards: [],
  accountUpdates: [],
  accountWithdrawals: [],

  poolListLoading: false,
  poolListDataLoading: false,
  poolInfoLoading: false,
  poolBlocksLoading: false,
  poolHistoryLoading: false,
  poolDelegatorsLoading: false,
  poolUpdatesLoading: false,
  poolRawDataLoading: false,
  poolList: [],
  poolListTotalResults: 0,
  poolInfo: null,
  poolBlocks: [],
  poolHistory: [],
  poolDelegators: [],
  poolUpdates: [],
  poolRawData: null,
}

export default (
  state: StakingAdaTypes.ISettings = initialState,
  action: StakingAdaTypes.Actions
): StakingAdaTypes.ISettings => {
  switch (action.type) {
    case StakingAdaTypes.Enum.CLEAR:
      return initialState
    case StakingAdaTypes.Enum.PREMIUM_POOLS_REQUEST:
      return {
        ...state,
        premiumPoolsLoading: true,
      }
    case StakingAdaTypes.Enum.PREMIUM_POOLS_CLEAR:
      return {
        ...state,
        premiumPoolsLoading: initialState.premiumPoolsLoading,
        premiumPools: initialState.premiumPools,
      }
    case StakingAdaTypes.Enum.PREMIUM_POOLS_FAILURE:
      return {
        ...state,
        premiumPoolsLoading: false,
      }
    case StakingAdaTypes.Enum.PREMIUM_POOLS_SUCCESS:
      return {
        ...state,
        premiumPoolsLoading: false,
        premiumPools: action.premiumPools,
      }
    case StakingAdaTypes.Enum.ACCOUNT_HISTORY_REQUEST:
      return {
        ...state,
        accountHistoryLoading: true,
      }
    case StakingAdaTypes.Enum.ACCOUNT_HISTORY_CLEAR:
      return {
        ...state,
        accountHistoryLoading: initialState.accountHistoryLoading,
        accountHistory: initialState.accountHistory,
      }
    case StakingAdaTypes.Enum.ACCOUNT_HISTORY_FAILURE:
      return {
        ...state,
        accountHistoryLoading: false,
      }
    case StakingAdaTypes.Enum.ACCOUNT_HISTORY_SUCCESS:
      return {
        ...state,
        accountHistoryLoading: false,
        accountHistory: action.accountHistory,
      }
    case StakingAdaTypes.Enum.ACCOUNT_REWARDS_REQUEST:
      return {
        ...state,
        accountRewardsLoading: true,
      }
    case StakingAdaTypes.Enum.ACCOUNT_REWARDS_CLEAR:
      return {
        ...state,
        accountRewardsLoading: initialState.accountRewardsLoading,
        accountRewards: initialState.accountRewards,
      }
    case StakingAdaTypes.Enum.ACCOUNT_REWARDS_FAILURE:
      return {
        ...state,
        accountRewardsLoading: false,
      }
    case StakingAdaTypes.Enum.ACCOUNT_REWARDS_SUCCESS:
      return {
        ...state,
        accountRewardsLoading: false,
        accountRewards: action.accountRewards,
      }
    case StakingAdaTypes.Enum.ACCOUNT_UPDATES_REQUEST:
      return {
        ...state,
        accountUpdatesLoading: true,
      }
    case StakingAdaTypes.Enum.ACCOUNT_UPDATES_CLEAR:
      return {
        ...state,
        accountUpdatesLoading: initialState.accountUpdatesLoading,
        accountUpdates: initialState.accountUpdates,
      }
    case StakingAdaTypes.Enum.ACCOUNT_UPDATES_FAILURE:
      return {
        ...state,
        accountUpdatesLoading: false,
      }
    case StakingAdaTypes.Enum.ACCOUNT_UPDATES_SUCCESS:
      return {
        ...state,
        accountUpdatesLoading: false,
        accountUpdates: action.accountUpdates,
      }
    case StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_REQUEST:
      return {
        ...state,
        accountWithdrawalsLoading: true,
      }
    case StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_CLEAR:
      return {
        ...state,
        accountWithdrawalsLoading: initialState.accountWithdrawalsLoading,
        accountWithdrawals: initialState.accountWithdrawals,
      }
    case StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_FAILURE:
      return {
        ...state,
        accountWithdrawalsLoading: false,
      }
    case StakingAdaTypes.Enum.ACCOUNT_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        accountWithdrawalsLoading: false,
        accountWithdrawals: action.accountWithdrawals,
      }
    case StakingAdaTypes.Enum.POOL_LIST_REQUEST:
      return {
        ...state,
        poolListLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_LIST_CLEAR:
      return {
        ...state,
        poolListLoading: initialState.poolListLoading,
        poolList: initialState.poolList,
        poolListTotalResults: initialState.poolListTotalResults,
      }
    case StakingAdaTypes.Enum.POOL_LIST_FAILURE:
      return {
        ...state,
        poolListLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_LIST_SUCCESS:
      return {
        ...state,
        poolListLoading: false,
        poolList: action.poolList,
        poolListTotalResults: action.poolListTotalResults,
      }
    case StakingAdaTypes.Enum.POOL_INFO_REQUEST:
      return {
        ...state,
        poolInfoLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_INFO_CLEAR:
      return {
        ...state,
        poolInfoLoading: initialState.poolInfoLoading,
        poolInfo: initialState.poolInfo,
      }
    case StakingAdaTypes.Enum.POOL_INFO_FAILURE:
      return {
        ...state,
        poolInfoLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_INFO_SUCCESS:
      return {
        ...state,
        poolInfoLoading: false,
        poolInfo: action.poolInfo,
      }
    case StakingAdaTypes.Enum.POOL_BLOCKS_REQUEST:
      return {
        ...state,
        poolBlocksLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_BLOCKS_CLEAR:
      return {
        ...state,
        poolBlocksLoading: initialState.poolBlocksLoading,
        poolBlocks: initialState.poolBlocks,
      }
    case StakingAdaTypes.Enum.POOL_BLOCKS_FAILURE:
      return {
        ...state,
        poolBlocksLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_BLOCKS_SUCCESS:
      return {
        ...state,
        poolBlocksLoading: false,
        poolBlocks: action.poolBlocks,
      }
    case StakingAdaTypes.Enum.POOL_HISTORY_REQUEST:
      return {
        ...state,
        poolHistoryLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_HISTORY_CLEAR:
      return {
        ...state,
        poolHistoryLoading: initialState.poolHistoryLoading,
        poolHistory: initialState.poolHistory,
      }
    case StakingAdaTypes.Enum.POOL_HISTORY_FAILURE:
      return {
        ...state,
        poolHistoryLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_HISTORY_SUCCESS:
      return {
        ...state,
        poolHistoryLoading: false,
        poolHistory: action.poolHistory,
      }
    case StakingAdaTypes.Enum.POOL_DELEGATORS_REQUEST:
      return {
        ...state,
        poolDelegatorsLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_DELEGATORS_CLEAR:
      return {
        ...state,
        poolDelegatorsLoading: initialState.poolDelegatorsLoading,
        poolDelegators: initialState.poolDelegators,
      }
    case StakingAdaTypes.Enum.POOL_DELEGATORS_FAILURE:
      return {
        ...state,
        poolDelegatorsLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_DELEGATORS_SUCCESS:
      return {
        ...state,
        poolDelegatorsLoading: false,
        poolDelegators: action.poolDelegators,
      }
    case StakingAdaTypes.Enum.POOL_UPDATES_REQUEST:
      return {
        ...state,
        poolUpdatesLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_UPDATES_CLEAR:
      return {
        ...state,
        poolUpdatesLoading: initialState.poolUpdatesLoading,
        poolUpdates: initialState.poolUpdates,
      }
    case StakingAdaTypes.Enum.POOL_UPDATES_FAILURE:
      return {
        ...state,
        poolUpdatesLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_UPDATES_SUCCESS:
      return {
        ...state,
        poolUpdatesLoading: false,
        poolUpdates: action.poolUpdates,
      }
    case StakingAdaTypes.Enum.POOL_RAW_DATA_REQUEST:
      return {
        ...state,
        poolRawDataLoading: true,
      }
    case StakingAdaTypes.Enum.POOL_RAW_DATA_CLEAR:
      return {
        ...state,
        poolRawDataLoading: initialState.poolUpdatesLoading,
        poolRawData: initialState.poolRawData,
      }
    case StakingAdaTypes.Enum.POOL_RAW_DATA_FAILURE:
      return {
        ...state,
        poolRawDataLoading: false,
      }
    case StakingAdaTypes.Enum.POOL_RAW_DATA_SUCCESS:
      return {
        ...state,
        poolRawDataLoading: false,
        poolRawData: action.poolRawData,
      }
    default:
      return state
  }
}
