import React from "react"
import { Row, Col } from "antd"
import { Helmet } from "react-helmet"
import Sidebar from "@/components/layout/Sidebar"
import Header from "@/components/layout/Header"
import Footer from "@/components/layout/Footer"
import Cookies from "@/components/layout/Cookies"
import MenuMobile from "@/components/layout/MenuMobile"
import ModalAccount from "@/components/modals/Account"
import ModalAdaTx from "@/components/modals/AdaTx"
import ModalTokensTx from "@/components/modals/TokensTx"
import * as style from "./style.module.scss"

const MainLayout = ({ children }: { children: any }) => {
  return (
    <>
      <Helmet titleTemplate="RayStake — %s" title="Cardano Staking Center">
        <meta
          name="viewport"
          content="width=device-width,viewport-fit=cover,initial-scale=1,shrink-to-fit=no,maximum-scale=1,user-scalable=0"
        />
        <link rel="stylesheet" type="text/css" href="/resources/font/satoshi.css" />
        <link rel="stylesheet" type="text/css" href="/resources/font/ray-icons.css" />
        <meta property="og:url" content="https://raystake.io" />
        <meta
          name="description"
          content="RayStake — Cardano Staking Center. Stake ADA, XRAY, XDIAMOND, LP tokens, other Cardano tokens and receive highest possible APY with RayStake."
        />
      </Helmet>
      <ModalAccount />
      <ModalAdaTx />
      <ModalTokensTx />
      <Row wrap={false} className={style.layout}>
        <Col flex="auto" className={style.side} />
        <Col flex="4.4rem" className={style.sidebar}>
          <Sidebar />
        </Col>
        <Col flex="1 1 1280px" className={style.content}>
          <Header />
          <div>{children}</div>
          <Footer />
        </Col>
        <Col flex="auto" />
      </Row>
      <MenuMobile />
      <Cookies />
    </>
  )
}

export default MainLayout
