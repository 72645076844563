import { all, takeEvery, put, call, select, fork, takeLatest, delay, cancelled } from "redux-saga/effects"
import type { RootState } from "../provider"
import { SettingsActions, SettingsTypes } from "@/redux/settings"
import { AccountActions, AccountTypes } from "@/redux/account"
import { TransactionActions, TransactionTypes } from "./"
import Koios, { KoiosTypes, AxiosError, AxiosResponse } from "@/services/koios-ts-client"
import { notification } from "antd"
import { truncate } from "@/utils/utils"
import config from "@/config/common"

export function* PENDING_TX_UPDATE_SAGA({ hash }: TransactionTypes.APendingTxsUpdateSaga) {
  const pendingTxs: TransactionTypes.PendingTxs = yield select((state: RootState) => state.transaction.pendingTxs)
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)

  yield put(
    TransactionActions.PENDING_TXS_SET({
      ...pendingTxs,
      [accountCurrent?.id]: {
        hash,
        sent: false,
        timestamp: Date.now(),
      },
    })
  )
}

export function* PENDING_TX_REMOVE_SAGA() {
  const pendingTxs: TransactionTypes.PendingTxs = yield select((state: RootState) => state.transaction.pendingTxs)
  const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)

  if (pendingTxs?.[accountCurrent?.id]) {
    const __pendingTxs = { ...pendingTxs }
    delete __pendingTxs[accountCurrent?.id]
    yield put(TransactionActions.PENDING_TXS_SET(__pendingTxs))
  }
}

export function* PENDING_TXS_CHECK_SAGA() {
  const abortController = new AbortController()
  try {
    const pendingTxs: TransactionTypes.PendingTxs = yield select((state: RootState) => state.transaction.pendingTxs)
    const accountCurrent: AccountTypes.Account = yield select((state: RootState) => state.account.accountCurrent)

    if (Object.keys(pendingTxs || {}).length === 0 || !accountCurrent) return

    const __pendingTxs = { ...pendingTxs }

    Object.entries(__pendingTxs).forEach(([key, value]) => {
      if (Date.now() - value?.timestamp > config.ttl * 1000) {
        delete __pendingTxs[key]
        if (key === accountCurrent?.id) {
          notification.error({
            message: "Transaction Expired",
            description: `TX ID: ${truncate(value.hash)}`,
          })
        }
      }
    })

    const txsFound: {
      success?: AxiosResponse<KoiosTypes.TxStatusResponse>
      error?: AxiosError
    } = yield call(
      Koios.TxStatus,
      {
        _tx_hashes: Object.entries(__pendingTxs).map(([key, value]) => {
          return value.hash
        }),
      },
      undefined,
      undefined,
      abortController.signal
    )

    if (txsFound?.success) {
      const __txsFound = txsFound?.success?.data.reduce((acc, tx) => {
        if (tx.tx_hash && parseInt(tx.num_confirmations?.toString() || "0") >= 0) {
          return [...acc, tx.tx_hash]
        } else {
          return acc
        }
      }, [] as string[])

      if (__txsFound.length > 0) {
        const txsNotApplied = Object.entries(__pendingTxs).reduce((acc, [key, value]) => {
          if (__txsFound.includes(value.hash) && !value.sent) {
            if (key === accountCurrent?.id) {
              notification.success({
                message: "Tx Succesfully Sent",
                description: `TX ID: ${truncate(value.hash)}`,
              })
            }
            return {
              ...acc,
              [accountCurrent?.id]: {
                ...value,
                sent: true,
              },
            }
          }
          return { ...acc, [accountCurrent?.id]: value }
        }, {})

        yield put(TransactionActions.PENDING_TXS_SET(txsNotApplied))
        yield put(AccountActions.ACCOUNT_INFO_UPDATE_SAGA("silent"))
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled()
    if (isCancelled) {
      abortController.abort()
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(TransactionTypes.Enum.PENDING_TX_UPDATE_SAGA, PENDING_TX_UPDATE_SAGA),
    takeLatest(TransactionTypes.Enum.PENDING_TX_REMOVE_SAGA, PENDING_TX_REMOVE_SAGA),
    takeLatest(TransactionTypes.Enum.PENDING_TXS_CHECK_SAGA, PENDING_TXS_CHECK_SAGA),
  ])
}
