export type NetworkId = "mainnet" | "preprod" | "preview"

export const envNetwork: NetworkId = process.env.GATSBY_NETWORK as any || "mainnet"
const networks = {
  mainnet: {
    name: "mainnet",
    id: 1,
  },
  preprod: {
    name: "preprod",
    id: 0,
  },
  preview: {
    name: "preview",
    id: 0,
  },
}
const network = networks[envNetwork]
if (!network) throw Error("Wrong Network!")
const cardanoScanUrl = `https://${network.name === "mainnet" ? "" : network.name}.cardanoscan.io`

export default {
  network,
  isMainnet: network.name === "mainnet",
  apis: {
    outputUrl: `https://output.${network.name}.raygraph.io`,
    kupoUrl: `https://kupo.${network.name}.raygraph.io`,
    imagesUrl: `https://cdn.${network.name}.raygraph.io`,
  },
  explorer: {
    transaction: `${cardanoScanUrl}/transaction/`,
    token: `${cardanoScanUrl}/token/`,
    block: `${cardanoScanUrl}/block/`,
    stakeKey: `${cardanoScanUrl}/stakekey/`,
    pool: `${cardanoScanUrl}/pool/`,
  },
  adaHandlePolicyId: "f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a",
  currencySymbols: {
    usd: "$",
    eur: "€",
    jpy: "¥",
    cny: "¥",
  },
  ttl: 1800,
}
