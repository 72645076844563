import React from "react"
import { Tooltip, message } from "antd"
import { CopyToClipboard } from "react-copy-to-clipboard"
import * as style from "./style.module.scss"

const Copy = ({ copy, title, children }: { copy: string | number | undefined; title: string; children: any }) => {
  return (
    <CopyToClipboard text={copy} onCopy={() => message.success("Copied")}>
      <Tooltip title={title}>{children}</Tooltip>
    </CopyToClipboard>
  )
}

export default Copy
