import React, { useState, useEffect } from "react"
import { AccountTypes } from "@/redux/account"
import * as style from "./style.module.scss"
// import TokenCached from "@/components/common/TokenCached"
import TokenAda from "@/components/common/TokenAda"
import Token from "@/components/common/Token"
import { CachedTokensTypes } from "@/redux/cachedTokens"
import { Tooltip } from "antd"

const TokensBreakdown = ({
  items,
}: {
  items:
    | {
        type: "lovelace"
        quantity: string
        help?: string
        prefix?: string
        hideable?: boolean
        shortened?: boolean
      }[]
    | {
        type: "simple"
        title?: string
        ticker: string
        quantity: string
        decimals: number
        help?: string
        prefix?: string
        hideable?: boolean
        shortened?: boolean
      }[]
}) => {
  return (
    <div>
      {items.map((item, index) => (
        <div key={index}>
          {item.type === "lovelace" && (
            <div className={style.item}>
              <div>
                <TokenAda />
              </div>
              <div className={style.dots} />
              <div className={style.amount}>
                {item.prefix && <span className={style.prefix}>{item.prefix}</span>}
                <Token amount={item.quantity} decimals={6} small tokenName="ADA" hideable={item.hideable} shortened={item.shortened} />
              </div>
            </div>
          )}
          {item.type === "simple" && (
            <div className={style.item}>
              {item.title && (
                <>
                  <div className={style.title}>
                    {item.title}
                    {item.help && (
                      <Tooltip title={item.help}>
                        <i className="ri ri-info ms-2 text-muted" />
                      </Tooltip>
                    )}
                  </div>
                  <div className={style.dots} />
                </>
              )}
              <div className={style.amount}>
                {item.prefix && <span className={style.prefix}>{item.prefix}</span>}
                <Token amount={item.quantity} decimals={item.decimals} small tokenName={item.ticker} hideable={item.hideable} shortened={item.shortened} />
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default TokensBreakdown
