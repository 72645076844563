import { Link } from "gatsby"
import React from "react"
import * as style from "./style.module.scss"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SVGCardano } from "@/svg"

const MenuMobile = () => {
  const tokensDefaultPage = useAppSelector((state) => state.settings.tokensDefaultPage)
  const adaDefaultPage = useAppSelector((state) => state.settings.adaDefaultPage)

  return (
    <div className={style.menu}>
      <Link
        to={`/tokens-staking${tokensDefaultPage}`}
        className={style.link}
        activeClassName={style.linkActive}
        partiallyActive
      >
        <div>
          <i className="ri ri-category" />
        </div>
        <div>Tokens</div>
      </Link>
      <Link
        to={`/ada-staking${adaDefaultPage}`}
        className={style.link}
        activeClassName={style.linkActive}
        partiallyActive
      >
        <div>
          <SVGCardano className={style.cardano} />
        </div>
        <div>ADA</div>
      </Link>
      <Link to="/calculator/" className={style.link} activeClassName={style.linkActive} partiallyActive>
        <div>
          <i className="ri ri-calculator" />
        </div>
        <div>Calculator</div>
      </Link>
    </div>
  )
}

export default MenuMobile
