import React, { useEffect, useState } from "react"
import { LoadingOutlined } from "@ant-design/icons"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import LinkCardanoscan from "@/components/common/LinkCardanoscan"

const Pending = () => {
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)
  const pendingTxs = useAppSelector((state) => state.transaction.pendingTxs)
  const tx = pendingTxs?.[accountCurrent?.id || ""]?.hash || ""

  return (
    <div className="ray-tx">
      <div className="ray-tx-head">
        <div className="ray-tx-head-icon text-blue">
          <LoadingOutlined style={{ fontSize: 72 }} spin />
        </div>
        <div className="ray-tx-head-title mt-2">Sending Tx...</div>
        <div className="text-center text-muted mt-2">
          <LinkCardanoscan title={tx} link={tx} type="transaction" />
        </div>
        <div className="ray-tx-head-description">
          Transaction has been sent to the queue, soon it will be inserted into the blockchain. You can close this
          window, it will not stop the transaction from being sent
        </div>
      </div>
    </div>
  )
}

export default Pending
