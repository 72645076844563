/**
 * Account Types
 */

import { KoiosTypes } from "@/services/koios-ts-client"

export type AccountInfoLoading = "default" | "silent" | undefined
export type AccountInfo = KoiosTypes.IAccountInfo
export type PoolInfo = KoiosTypes.IPoolInfo
export type BalanceToken = {
  quantity: string
  asset: {
    assetId: string
    assetName: string | null
    assetNameAscii: string | null
    fingerprint: string
    policyId: string
  }
}
export type BalanceUtxo = {
  txHash: string
  txIndex: number
  address: string
  value: string
  datumHash: string | null
  datumType?: string
  scriptHash: string | null
  tokens: BalanceToken[]
}
export type Account = {
  v: "v1"
  id: string
  connectorId: string
  checksum: string
  stakeKey: string
  type: "readonly" | "connector" | "hw"
  online: boolean
}
export interface ModalAccount {
  open: boolean
  type: "connect" | "account" | "settings" | ""
}

export interface ISettings {
  modalAccount: ModalAccount

  accountCurrent: Account | null
  accountsList: Account[]

  accountInfoLoading: AccountInfoLoading
  accountInfo: AccountInfo | null
  poolInfo: PoolInfo | null

  balanceLoading: boolean
  balanceBootstrapped: boolean
  balanceAda: string | undefined
  balanceTokens: BalanceToken[]
  balanceUtxos: BalanceUtxo[]
  balanceAddresses: string[]
}

/**
 * Account Actions
 */

export enum Enum {
  INIT = "account/INIT",
  CLEAR = "account/CLEAR",

  MODAL_ACCOUNT_SET = "account/MODAL_ACCOUNT_SET",

  ACCOUNT_CURRENT_SET = "account/ACCOUNT_CURRENT_SET",
  ACCOUNTS_LIST_SET = "account/ACCOUNTS_LIST_SET",
  ACCOUNT_ADD_SAGA = "account/ACCOUNT_ADD_SAGA",
  ACCOUNT_REMOVE_SAGA = "account/ACCOUNT_REMOVE_SAGA",

  ACCOUNT_INFO_UPDATE_SAGA = "account/ACCOUNT_INFO_UPDATE_SAGA",
  ACCOUNT_INFO_CLEAR = "account/ACCOUNT_INFO_CLEAR",
  ACCOUNT_INFO_REQUEST = "account/ACCOUNT_INFO_REQUEST",
  ACCOUNT_INFO_FAILURE = "account/ACCOUNT_INFO_FAILURE",
  ACCOUNT_INFO_SUCCESS = "account/ACCOUNT_INFO_SUCCESS",

  BALANCE_UPDATE_SAGA = "account/BALANCE_UPDATE_SAGA",
  BALANCE_CLEAR = "account/BALANCE_CLEAR",
  BALANCE_REQUEST = "account/BALANCE_REQUEST",
  BALANCE_FAILURE = "account/BALANCE_FAILURE",
  BALANCE_SUCCESS = "account/BALANCE_SUCCESS",

  CONNECTORS_UPDATE_STATUS_SAGA = "account/CONNECTORS_UPDATE_STATUS_SAGA",
}

export type AInit = {
  type: typeof Enum.INIT
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AModalAccountSet = {
  type: typeof Enum.MODAL_ACCOUNT_SET
  modalAccount: ModalAccount
}

export type AAccountCurrentSet = {
  type: typeof Enum.ACCOUNT_CURRENT_SET
  account: Account | null
}

export type AAccountsListSet = {
  type: typeof Enum.ACCOUNTS_LIST_SET
  accountsList: Account[]
}

export type AAccountAddSaga = {
  type: typeof Enum.ACCOUNT_ADD_SAGA
  account: Account
}

export type AAccountRemoveSaga = {
  type: typeof Enum.ACCOUNT_REMOVE_SAGA
  account: Account
}

export type AAccountInfoUpdateSaga = {
  type: typeof Enum.ACCOUNT_INFO_UPDATE_SAGA
  loadingType: AccountInfoLoading
  clear?: boolean
}

export type AAccountInfoClear = {
  type: typeof Enum.ACCOUNT_INFO_CLEAR
}

export type AAccountInfoRequest = {
  type: typeof Enum.ACCOUNT_INFO_REQUEST
  loadingType: AccountInfoLoading
}

export type AAccountInfoFailure = {
  type: typeof Enum.ACCOUNT_INFO_FAILURE
}

export type AAccountInfoSuccess = {
  type: typeof Enum.ACCOUNT_INFO_SUCCESS
  accountInfo: AccountInfo | null
  poolInfo?: PoolInfo | null
}

export type ABalanceUpdateSaga = {
  type: typeof Enum.BALANCE_UPDATE_SAGA
  clear?: boolean
}

export type ABalanceClear = {
  type: typeof Enum.BALANCE_CLEAR
}

export type ABalanceRequest = {
  type: typeof Enum.BALANCE_REQUEST
}

export type ABalanceFailure = {
  type: typeof Enum.BALANCE_FAILURE
}

export type ABalanceSuccess = {
  type: typeof Enum.BALANCE_SUCCESS
  balanceAda: string | undefined
  balanceTokens: BalanceToken[]
  balanceUtxos: BalanceUtxo[]
  balanceAddresses: string[]
}

export type AConnectorsUpdateStatusSaga = {
  type: typeof Enum.CONNECTORS_UPDATE_STATUS_SAGA
}

export type Actions =
  | AInit
  | AClear
  | AModalAccountSet
  | AAccountCurrentSet
  | AAccountsListSet
  | AAccountAddSaga
  | AAccountRemoveSaga
  | AAccountInfoUpdateSaga
  | AAccountInfoClear
  | AAccountInfoRequest
  | AAccountInfoFailure
  | AAccountInfoSuccess
  | ABalanceUpdateSaga
  | ABalanceClear
  | ABalanceRequest
  | ABalanceFailure
  | ABalanceSuccess
  | AConnectorsUpdateStatusSaga
