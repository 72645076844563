import React from "react"
import { amountFormat } from "@/utils/utils"
import * as style from "./style.module.scss"

const InformerDoubleLine = ({
  amountA,
  tokenNameA,
  helpA,
  amountB,
  tokenNameB,
  helpB,
}: {
  amountA: string
  tokenNameA?: string
  helpA?: string
  amountB: string
  tokenNameB?: string
  helpB?: string
}) => {
  const { a: aa, b: ab } = amountFormat(amountA)
  const { a: ba, b: bb } = amountFormat(amountB)

  return (
    <div className={style.informer}>
      <div className={style.body}>
        <span>{aa}</span>
        <span>
          {ab && <span>.{ab}</span>} <span>{tokenNameA}</span> {helpA && <sup>{helpA}</sup>}{" "}
        </span>
      </div>
      <div className={style.body}>
        <span>{ba}</span>
        <span>
          {bb && <span>.{bb}</span>} <span>{tokenNameB}</span> {helpB && <sup>{helpB}</sup>}{" "}
        </span>
      </div>
    </div>
  )
}

export default InformerDoubleLine
