import React from "react"
import { Tooltip } from "antd"
import { amountFormat } from "@/utils/utils"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import * as style from "./style.module.scss"

const Token = ({
  amount,
  tokenName,
  small,
  decimals,
  hideDecimals,
  shortened,
  hideable,
}: {
  amount: string
  tokenName?: string
  small?: boolean
  decimals?: number
  hideDecimals?: boolean
  shortened?: boolean
  hideable?: boolean
}) => {
  const hideBalance = useAppSelector((state) => state.settings.hideBalance)
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)
  const __hideBalance = hideable ? hideBalance[accountCurrent?.id || ""] : false

  const { a, b, final } = amountFormat(amount, decimals)
  const short =
    Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 2 }).format(
      Number(final.replaceAll(",", ""))
    ) || "0"

  return (
    <div className={style.informer}>
      <div className={style.body}>
        {__hideBalance && "*****"}
        {!__hideBalance && (
          <>
            <strong>{!shortened ? a : <Tooltip title={`${final} ${tokenName}`}>{short}</Tooltip>}</strong>
            <span className={`${style.postfix} ${small ? style.postfixSmall : ""}`}>
              {b && !hideDecimals && !shortened && <span>.{b}</span>} <span>{tokenName}</span>
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default Token
