/**
 * Transaction Types
 */

export interface ITx {
  hash: string
}
export interface ModalAdaTx {
  open: boolean
  type: "withdraw" | "delegate" | "deregister" | ""
  poolId?: string
  poolTicker?: string
}
export interface ModalTokensTx {
  open: boolean
  type: "add" | "withdraw" | "create" | ""
}
export type PendingTxs = {
  [key: string]: {
    hash: string
    sent: boolean
    timestamp: number
  }
}

export interface ISettings {
  modalAdaTx: ModalAdaTx
  modalTokensTx: ModalTokensTx
  pendingTxs: PendingTxs | null
}

/**
 * Transaction Actions
 */

export enum Enum {
  CLEAR = "transaction/CLEAR",

  MODAL_ADA_TRANSACTION_SET = "transaction/MODAL_ADA_TRANSACTION_SET",
  MODAL_TOKENS_TRANSACTION_SET = "transaction/MODAL_TOKENS_TRANSACTION_SET",

  PENDING_TXS_SET = "transaction/PENDING_TXS_SET",
  PENDING_TX_UPDATE_SAGA = "transaction/PENDING_TX_UPDATE_SAGA",
  PENDING_TX_REMOVE_SAGA = "transaction/PENDING_TX_REMOVE_SAGA",
  PENDING_TXS_CHECK_SAGA = "transaction/PENDING_TXS_CHECK_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AModalAdaTxSet = {
  type: typeof Enum.MODAL_ADA_TRANSACTION_SET
  modalAdaTx: ModalAdaTx
}

export type AModalTokensTxSet = {
  type: typeof Enum.MODAL_TOKENS_TRANSACTION_SET
  modalTokensTx: ModalTokensTx
}

export type APendingTxsSet = {
  type: typeof Enum.PENDING_TXS_SET
  pendingTxs: PendingTxs
}

export type APendingTxsUpdateSaga = {
  type: typeof Enum.PENDING_TX_UPDATE_SAGA
  hash: string
}

export type APendingTxsRemoveSaga = {
  type: typeof Enum.PENDING_TX_REMOVE_SAGA
}

export type APendingTxsCheckSaga = {
  type: typeof Enum.PENDING_TXS_CHECK_SAGA
}

export type Actions =
  | AClear
  | AModalAdaTxSet
  | AModalTokensTxSet
  | APendingTxsSet
  | APendingTxsUpdateSaga
  | APendingTxsRemoveSaga
  | APendingTxsCheckSaga
