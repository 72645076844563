import { TransactionTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.transaction."
const initialState: TransactionTypes.ISettings = {
  modalAdaTx: {
    open: false,
    type: "",
  },
  modalTokensTx: {
    open: false,
    type: "",
  },

  pendingTxs: null,
}

export default (
  state: TransactionTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: TransactionTypes.Actions
): TransactionTypes.ISettings => {
  switch (action.type) {
    case TransactionTypes.Enum.CLEAR:
      return initialState

    case TransactionTypes.Enum.MODAL_ADA_TRANSACTION_SET:
      return {
        ...state,
        modalAdaTx: action.modalAdaTx,
      }

    case TransactionTypes.Enum.MODAL_TOKENS_TRANSACTION_SET:
      return {
        ...state,
        modalTokensTx: action.modalTokensTx,
      }

    case TransactionTypes.Enum.PENDING_TXS_SET:
      setPersistSetting(persistStorePrefix, "pendingTxs", action.pendingTxs)
      return {
        ...state,
        pendingTxs: action.pendingTxs,
      }

    default:
      return state
  }
}
