import React, { useEffect, useState } from "react"
import { Button, Tabs, message, Select, Radio, Row, Col, Input, Checkbox } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import TokensBreakdown from "@/components/common/TokensBreakdown"
import Error from "../Error"
import * as style from "./style.module.scss"

const Delegate = () => {
  const dispatch = useAppDispatch()
  const modalAdaTx = useAppSelector((state) => state.transaction.modalTokensTx)

  const [type, setType] = useState("apr")
  const [txError, setTxError] = useState("")

  const submitTx = async () => {
    console.log('tx::submit')
  }

  return (
    <div>
      {txError && <Error text={txError} />}
      {!txError && (
        <div>
          <Tabs
            className="ray-tabs-wide ray-tabs-compact ray-tabs-negative-margin ray-tabs-nomore"
            destroyInactiveTabPane
            defaultActiveKey="create"
            items={[
              {
                key: "creat",
                label: "Create Pool",
              },
            ]}
          />
          <div className="mb-4">
            <h6 className="mb-3">Pool Type</h6>
            <Radio.Group
              value={type}
              onChange={(value) => setType(value.target.value)}
              className="w-100p d-flex"
              size="large"
            >
              <Radio.Button value="apr"  className="text-center flex-grow-1"><strong>APR</strong></Radio.Button>
              <Radio.Button value="ispo" className="text-center flex-grow-1"><strong>ISPO</strong></Radio.Button>
              <Radio.Button value="lp" className="text-center flex-grow-1"><strong>LP</strong></Radio.Button>
              <Radio.Button value="drop" className="text-center flex-grow-1"><strong>DROP</strong></Radio.Button>
            </Radio.Group>
            {/* <Select
              value={type}
              onChange={(value) => setType(value)}
              className="w-100p"
              size="large"
              options={[
                { value: "apr", label: "APR" },
                { value: "ispo", label: "ISPO" },
                { value: "lp", label: "LP" },
                { value: "drop", label: "DROP" },
              ]}
            /> */}
          </div>
          <div className="mb-4">
            <h6 className="mb-3">Stake Token</h6>
            <Select
              value="xray"
              className="w-100p"
              size="large"
              placeholder="Select Token"
              allowClear
            >
              <Select.Option key="xray" value="xray">
                <div className="d-flex align-items-center">
                  <span>XRAY</span>
                  <span className="ms-2 font-size-14 text-muted">asse...fhgx</span>
                </div>
              </Select.Option>
            </Select>
          </div>
          <Row gutter={[20, 0]}>
            <Col span={24} md={12}>
              <div className="mb-4">
                <h6 className="mb-3">APR</h6>
                <Input placeholder="Enter APR rate..." size="large" />
              </div>
            </Col>
            <Col span={24} md={12}>
              <div className="mb-4">
                <h6 className="mb-3">Initial Rewards</h6>
                <Input placeholder="Enter amount..." size="large" />
              </div>
            </Col>
          </Row>
          <div className={`${style.info} mt-1 mb-4`}>
            <div className="text-mono font-size-14 mb-2">
              <strong>Based on your input, stakers will get 0% annual percentage rate, and will be receiving 0 XRAY per every 1 XRAY staked in every 1 epoch</strong>
            </div>
            <div className="mb-2">
              <Checkbox checked className="text-muted font-size-14">
                Pay 20,000 XRAY to make the pool a premium (not required)
              </Checkbox>
            </div>
            <div>
              <Checkbox checked className="text-muted font-size-14">
                I agree to <a href="https://raynetwork.io/terms-of-use/" target="_blank">Terms of Service & Privacy Policy</a>
              </Checkbox>
            </div>
          </div>
          <div className="mb-2">
            <TokensBreakdown
              items={[
                {
                  type: "simple",
                  title: "Tx Fee",
                  ticker: "ADA",
                  quantity: "185647",
                  decimals: 6,
                  help: "This is the fee you will pay to create the pool. It is paid in ADA and is non-refundable."
                },
                {
                  type: "simple",
                  title: "Oil",
                  ticker: "ADA",
                  quantity: "2000000",
                  decimals: 6,
                  help: "Will be returned after tx processing"
                },
                {
                  type: "simple",
                  title: "New Pool Setup Fee",
                  ticker: "ADA",
                  quantity: "1000000000",
                  decimals: 6,
                },
                {
                  type: "simple",
                  title: "Make Premium",
                  ticker: "XRAY",
                  quantity: "20000000000",
                  decimals: 6,
                },
              ]}
            />
          </div>
          <div className="mb-4">
            <TokensBreakdown
              items={[
                {
                  type: "simple",
                  title: "Tx Fee",
                  ticker: "ADA",
                  quantity: "1002185647",
                  decimals: 6,
                  help: "This is the fee you will pay to create the pool. It is paid in ADA and is non-refundable."
                },
                {
                  type: "simple",
                  ticker: "XRAY",
                  quantity: "20000000000",
                  decimals: 6,
                },
              ]}
            />
          </div>
          <div>
            <Button
              type="primary"
              size="large"
              className="w-100p"
              onClick={submitTx}
            >
              <i className="ri ri-add me-2" />
              Create Pool
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Delegate
