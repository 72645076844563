import React from "react"
import { Link } from "gatsby"
import { Tooltip } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { SettingsActions } from "@/redux/settings"
import { SVGPremium } from "@/svg"
import * as style from "./style.module.scss"
import { AccountActions } from "@/redux/account"

const InformerPool = ({
  ticker,
  poolId,
  title,
  help,
}: {
  ticker: string
  poolId: string
  title: string
  help?: string
}) => {
  const dispatch = useAppDispatch()
  const premiumPool = useAppSelector((state) => state.stakingAda.premiumPools)

  return (
    <div className={style.informer}>
      {poolId && (
        <Link
          to={`/ada-staking/pool/${poolId}`}
          className={style.body}
          onClick={() => dispatch(AccountActions.MODAL_ACCOUNT_SET({ open: false, type: "" }))}
        >
          <div>
            <strong>{ticker}</strong>
          </div>
          <div className={style.arrow}>
            <i className="ri ri-arrow_up ri-rotate" />
          </div>
          {premiumPool.some((i) => i.pool_id_bech32 === poolId) && (
            <Tooltip title="Promoted Pool">
              <div className="ray-premium ms-2">
                <SVGPremium />
              </div>
            </Tooltip>
          )}
        </Link>
      )}
      {!poolId && (
        <div className={style.body}>
          <strong>—</strong>
        </div>
      )}
      <div className={style.title}>
        {title}{" "}
        {help && (
          <Tooltip title={help}>
            <i className="ri ri-info ms-1" />
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default InformerPool
