import { TransactionTypes } from "./"

export function CLEAR(): TransactionTypes.AClear {
  return { type: TransactionTypes.Enum.CLEAR }
}

export function MODAL_ADA_TRANSACTION_SET(modalAdaTx: TransactionTypes.ModalAdaTx): TransactionTypes.AModalAdaTxSet {
  return { type: TransactionTypes.Enum.MODAL_ADA_TRANSACTION_SET, modalAdaTx }
}

export function MODAL_TOKENS_TRANSACTION_SET(modalTokensTx: TransactionTypes.ModalTokensTx): TransactionTypes.AModalTokensTxSet {
  return { type: TransactionTypes.Enum.MODAL_TOKENS_TRANSACTION_SET, modalTokensTx }
}

export function PENDING_TXS_SET(pendingTxs: TransactionTypes.PendingTxs): TransactionTypes.APendingTxsSet {
  return { type: TransactionTypes.Enum.PENDING_TXS_SET, pendingTxs }
}

export function PENDING_TX_UPDATE_SAGA(hash: string): TransactionTypes.APendingTxsUpdateSaga {
  return { type: TransactionTypes.Enum.PENDING_TX_UPDATE_SAGA, hash }
}

export function PENDING_TX_REMOVE_SAGA(): TransactionTypes.APendingTxsRemoveSaga {
  return { type: TransactionTypes.Enum.PENDING_TX_REMOVE_SAGA }
}

export function PENDING_TXS_CHECK_SAGA(): TransactionTypes.APendingTxsCheckSaga {
  return { type: TransactionTypes.Enum.PENDING_TXS_CHECK_SAGA }
}
