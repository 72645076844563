import React, { useEffect, useState, useRef } from "react"
import { Modal, Button, Tabs, Input, Tooltip, message, Tag } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import Informer from "@/components/informers"
import Skeleton from "@/components/loading/Skeleton"
import Jazzicon from "react-jazzicon"
import { truncate, numberFromString } from "@/utils/utils"
import AccountTokensTable from "@/components/tables/AccountTokens"
import { SVGAdaHandle } from "@/svg"
import { NetworkActions, NetworkTypes } from "@/redux/network"
import ViewSwitcher from "@/components/common/ViewSwitcher"
import AccountAvatar from "@/components/common/AccountAvatar"
import config from "@/config/common"

const ModalAccount = () => {
  const dispatch = useAppDispatch()
  const searchInput = useRef<any>()

  const appCurrency = useAppSelector((state) => state.settings.currency)
  const accountInfoLoading = useAppSelector((state) => state.account.accountInfoLoading)
  const accountInfo = useAppSelector((state) => state.account.accountInfo)
  const poolInfo = useAppSelector((state) => state.account.poolInfo)
  const balanceLoading = useAppSelector((state) => state.account.balanceLoading)
  const balanceTokens = useAppSelector((state) => state.account.balanceTokens)
  const cachedTokens = useAppSelector((state) => state.cachedTokens.cachedTokens)
  const exchangeRates = useAppSelector((state) => state.network.exchangeRates)
  const adaHandle = useAppSelector((state) => state.adaHandle.current)
  const adaHandleDisabled = useAppSelector((state) => state.adaHandle.disabled)
  const accountCurrent = useAppSelector((state) => state.account.accountCurrent)

  const [typeKey, setTypeKey] = useState("all")
  const [searchString, setSearchString] = useState("")

  useEffect(() => {
    const handleSearchFocus = (e: any) => {
      try {
        if (e.code === "Slash") searchInput.current?.focus()
      } catch {}
    }
    window?.addEventListener("keyup", handleSearchFocus)
    return () => {
      window?.removeEventListener("keyup", handleSearchFocus)
    }
  }, [])

  const restLoading = accountInfoLoading === "default" || balanceLoading

  const tokensAll = balanceTokens
    .map((token) => {
      const cachedToken = cachedTokens[token.asset.fingerprint]
      return {
        ...token,
        asset_info: cachedToken?.data,
      }
    })
    .filter((i) => {
      const __search = searchString.toLowerCase()
      return (
        i.asset.assetNameAscii?.toLowerCase().includes(__search) ||
        i.asset.fingerprint?.toLowerCase().includes(__search) ||
        i.asset.policyId?.toLowerCase().includes(__search)
      )
    })

  const tokensFT = [...tokensAll].filter((i) => i.asset_info?.total_supply !== "1")
  const tokensNFT = [...tokensAll].filter((i) => i.asset_info?.total_supply === "1")

  const tokensList = (() => {
    if (typeKey === "ft") return tokensFT
    if (typeKey === "nft") return tokensNFT
    return tokensAll
  })()

  const isAdaHandleEnabled = !adaHandleDisabled[accountCurrent?.id || ""] && adaHandle?.[accountCurrent?.id || ""]

  return (
    <div>
      <div>
        <Skeleton loading={restLoading}>
          <div className="overflow-hidden">
            <div className="d-flex align-items-center flex-wrap me--5">
              <div className="me-5 mb-4">
                <Informer.Pool
                  title="Delegated To"
                  poolId={accountInfo?.delegated_pool || ""}
                  ticker={poolInfo?.meta_json?.ticker || ""}
                  help="Your staking certificate is registered in this pool"
                />
              </div>
              <div className="me-5 mb-4">
                <Informer.Token
                  title="Account Balance"
                  tokenName="ADA"
                  amount={accountInfo?.total_balance || ""}
                  help="Your current balance, including unclaimed rewards"
                />
              </div>
              <div className="me-5 mb-4">
                <Informer.Currency
                  title="ADA Price"
                  amount={exchangeRates?.cardano?.[appCurrency] || 0}
                  currency={config.currencySymbols[appCurrency] || ""}
                  help="ADA current market price"
                />
              </div>
            </div>
          </div>
        </Skeleton>
      </div>
      <div className="ray-line mt-1 mb-4 pb-2" />
      <div className="pb-3 d-flex">
        <div className="flex-grow-1 me-2">
          <Input
            ref={searchInput}
            prefix={<i className="ri ri-search me-1 text-muted" />}
            suffix={<i className="ray-search-suffix" />}
            size="large"
            placeholder="Search by Asset Name, Fingerprint, or Policy ID"
            onChange={(e) => setSearchString(e.target.value)}
            value={searchString}
            allowClear
          />
        </div>
        {/* <div>
          <ViewSwitcher
            onChange={(key) => setTypeKey(key)}
            value={typeKey}
            items={[
              {
                key: "all",
                icon: `ALL`,
              },
              {
                key: "ft",
                icon: `FT`,
              },
              {
                key: "nft",
                icon: `NFT`,
              },
            ]}
          />
        </div> */}
      </div>
      <Tabs
        className="ray-tabs-compact ray-tabs-empty"
        // destroyInactiveTabPane
        activeKey={typeKey}
        onChange={(key) => setTypeKey(key)}
        items={[
          {
            key: "all",
            label: (
              <div className="d-flex align-items-center">
                <span className="me-2">All Tokens</span>
                <Tag color="blue" className="me-0">
                  {tokensAll.length}
                </Tag>
              </div>
            ),
          },
          {
            key: "ft",
            label: (
              <div className="d-flex align-items-center">
                <span className="me-2">FT</span>
                <Tag color="blue" className="me-0">
                  {tokensFT.length}
                </Tag>
              </div>
            ),
          },
          {
            key: "nft",
            label: (
              <div className="d-flex align-items-center">
                <span className="me-2">NFT</span>
                <Tag color="blue" className="me-0">
                  {tokensNFT.length}
                </Tag>
              </div>
            ),
          },
        ]}
      />
      <div>
        <AccountTokensTable data={tokensList} loading={balanceLoading} size="small" wide={false} />
      </div>
    </div>
  )
}

export default ModalAccount
