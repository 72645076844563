import { NetworkTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const initialState: NetworkTypes.ISettings = {
  online: true,
  loading: false,
  tip: null,
  epochCurrent: null,
  exchangeRates: null,
}

const persistStorePrefix = "app.network."

export default (
  state: NetworkTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: NetworkTypes.Actions
): NetworkTypes.ISettings => {
  switch (action.type) {
    case NetworkTypes.Enum.TIP_SET:
      return { ...state, tip: action.tip }

    case NetworkTypes.Enum.NETWORK_STATUS_SET:
      return { ...state, online: action.online }

    case NetworkTypes.Enum.EPOCH_CURRENT_SET:
      setPersistSetting(persistStorePrefix, "epochCurrent", action.epochCurrent)
      return { ...state, epochCurrent: action.epochCurrent }

    case NetworkTypes.Enum.EXCHANGE_RATES_SET:
      return { ...state, exchangeRates: action.exchangeRates }

    default:
      return state
  }
}
