import React, { useState, useEffect } from "react"
import { SyncOutlined } from "@ant-design/icons"
import { Link } from "gatsby"
import { SVGPremium } from "@/svg"
import { Tabs, Tag, Badge, Button } from "antd"
import HorizontalScroll from "@/components/common/HorizontalScroll"
import { useAppDispatch, useAppSelector } from "@/redux/provider"
import { TransactionActions, TransactionTypes } from "@/redux/transaction"

const TokensStakingLayout = ({ children, location }: { children: any; location: any }) => {
  const dispatch = useAppDispatch()
  const tokensBookmarks = useAppSelector((state) => state.settings.tokensBookmarks)
  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimated(true)
    }, 200)
  }, [])

  const defaultActiveKey = location.pathname.includes("/ada-staking/pool/") ? "/ada-staking/pool/" : location.pathname

  return (
    <>
      <div className="ray-title mb-3">
        <HorizontalScroll>
          <div>
            <div className="d-flex align-items-center text-nowrap text-nowrap">
              <h1 className="ray-title-head me-3">Tokens Staking</h1>
              <Button
                size="large"
                className="ray-btn ray-btn-outline ms-auto"
                onClick={(e) => {
                  e.stopPropagation()
                  dispatch(
                    TransactionActions.MODAL_TOKENS_TRANSACTION_SET({
                      open: true,
                      type: "create",
                    })
                  )
                }}
              >
                <i className="ri ri-add me-2" />
                Create Pool
              </Button>
            </div>
          </div>
        </HorizontalScroll>
      </div>
      <Tabs
        className="ray-tabs-nomore ray-tabs-links mb-3"
        destroyInactiveTabPane
        activeKey={defaultActiveKey}
        animated={animated}
        items={[
          {
            key: "/tokens-staking/",
            label: <Link to="/tokens-staking/">Pools List</Link>,
          },
          {
            key: "/tokens-staking/premium/",
            label: (
              <Link to="/tokens-staking/premium/" className="d-flex align-items-center">
                <span>Promoted</span>
                <span className="ray-premium ms-1">
                  <SVGPremium />
                </span>
              </Link>
            ),
          },
          {
            key: "/tokens-staking/bookmarks/",
            label: (
              <Link to="/tokens-staking/bookmarks" className="d-flex align-items-center">
                <span>Bookmarks</span>
                {!!tokensBookmarks.length && (
                  <Tag color="blue" className="ms-2 me-0">
                    {tokensBookmarks.length}
                  </Tag>
                )}
              </Link>
            ),
          },
          {
            key: "/tokens-staking/my-position/",
            label: (
              <Link to="/tokens-staking/my-position/" className="d-flex align-items-center">
                <span>My Position</span>
                <Tag color="blue" className="ms-2 me-0">
                  2
                </Tag>
              </Link>
            ),
          },
          {
            key: "/tokens-staking/orders/",
            label: (
              <Link to="/tokens-staking/orders/" className="d-flex align-items-center">
                <span>Orders</span>
                <div className="ray-spinner-tag">
                  <div className="ray-spinner" />
                  <Tag color="blue" className="ms-2 me-0">
                    3
                  </Tag>
                </div>
              </Link>
            ),
          },
        ]}
      />
      {children}
    </>
  )
}

export default TokensStakingLayout
