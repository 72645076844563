import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { useAppDispatch } from "@/redux/provider"
import * as style from "./style.module.scss"
import { useAppSelector } from "@/redux/provider"
import { SVGCardano, SVGAdaHandle } from "@/svg"
import config from "@/config/common"
import { CachedTokensActions, CachedTokensTypes } from "@/redux/cachedTokens"

const TokenCached = ({
  asset,
  maxWidth = 250,
  disableUpdateCache,
}: {
  asset: CachedTokensTypes.ITokenRaw
  maxWidth?: number
  disableUpdateCache?: boolean
}) => {
  const dispatch = useAppDispatch()
  const cachedTokens = useAppSelector((state) => state.cachedTokens.cachedTokens)
  const cachedToken = cachedTokens[asset.fingerprint]?.data
  const isHandle = asset.policyId === config.adaHandlePolicyId
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  useEffect(() => {
    if (!disableUpdateCache) {
      dispatch(CachedTokensActions.CACHED_TOKENS_UPDATE_SAGA([asset]))
    }
  }, [])

  return (
    <div className={style.token} style={{ maxWidth }}>
      <div className={style.tokenLogo}>
        {cachedToken?.token_registry_metadata && (
          <img
            src={`${config.apis.imagesUrl}/registry/64/${asset.fingerprint}`}
            alt={asset.assetNameAscii || cachedToken?.asset_name_ascii || ""}
            style={{ display: "none" }}
            onLoad={(event) => {
              // @ts-ignore
              event.target.style.display = "block"
              setShowPlaceholder(false)
            }}
          />
        )}
        {/* @ts-ignore */}
        {!cachedToken?.token_registry_metadata && cachedToken?.minting_tx_metadata?.["721"] && (
          <img
            src={`${config.apis.imagesUrl}/721/64/${asset.fingerprint}`}
            alt={asset.assetNameAscii || cachedToken?.asset_name_ascii || ""}
            style={{ display: "none" }}
            onLoad={(event) => {
              // @ts-ignore
              event.target.style.display = "block"
              setShowPlaceholder(false)
            }}
          />
        )}
        {/* @ts-ignore */}
        {((!cachedToken?.token_registry_metadata && !cachedToken?.minting_tx_metadata?.["721"]) || showPlaceholder) && (
          <SVGCardano />
        )}
      </div>
      <span className={style.tokenTitle}>
        {isHandle && (
          <>
            <span className="ray-handle-icon">
              <SVGAdaHandle />
            </span>
            <span className="ray-handle-title">{asset.assetNameAscii}</span>
          </>
        )}
        {!isHandle && <strong>{asset.assetNameAscii || cachedToken?.asset_name_ascii || ""}</strong>}
      </span>
    </div>
  )
}

export default TokenCached
