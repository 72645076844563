import { AccountTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.account."
const initialState: AccountTypes.ISettings = {
  accountCurrent: null,
  accountsList: [],

  accountInfoLoading: undefined,
  accountInfo: null,
  poolInfo: null,

  balanceLoading: false,
  balanceBootstrapped: false,
  balanceAda: undefined,
  balanceTokens: [],
  balanceUtxos: [],
  balanceAddresses: [],

  modalAccount: {
    open: false,
    type: "",
  },
}

export default (
  state: AccountTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: AccountTypes.Actions
): AccountTypes.ISettings => {
  switch (action.type) {
    case AccountTypes.Enum.CLEAR:
      return initialState

    case AccountTypes.Enum.MODAL_ACCOUNT_SET:
      return { ...state, modalAccount: action.modalAccount }

    case AccountTypes.Enum.ACCOUNT_CURRENT_SET:
      setPersistSetting(persistStorePrefix, "accountCurrent", action.account)
      return { ...state, accountCurrent: action.account }

    case AccountTypes.Enum.ACCOUNTS_LIST_SET:
      setPersistSetting(persistStorePrefix, "accountsList", action.accountsList)
      return { ...state, accountsList: action.accountsList }

    case AccountTypes.Enum.ACCOUNT_INFO_REQUEST:
      return {
        ...state,
        accountInfoLoading: action.loadingType,
      }

    case AccountTypes.Enum.ACCOUNT_INFO_CLEAR:
      return {
        ...state,
        accountInfoLoading: initialState.accountInfoLoading,
        accountInfo: initialState.accountInfo,
        poolInfo: initialState.poolInfo,
      }

    case AccountTypes.Enum.ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        accountInfoLoading: undefined,
        accountInfo: action.accountInfo,
        poolInfo: action.poolInfo ? action.poolInfo : state.poolInfo,
      }

    case AccountTypes.Enum.ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        accountInfoLoading: undefined,
      }

    case AccountTypes.Enum.BALANCE_REQUEST:
      return {
        ...state,
        balanceLoading: true,
      }

    case AccountTypes.Enum.BALANCE_CLEAR:
      return {
        ...state,
        balanceLoading: initialState.balanceLoading,
        balanceAda: initialState.balanceAda,
        balanceTokens: initialState.balanceTokens,
        balanceUtxos: initialState.balanceUtxos,
        balanceAddresses: initialState.balanceAddresses,
      }

    case AccountTypes.Enum.BALANCE_SUCCESS:
      return {
        ...state,
        balanceLoading: false,
        balanceBootstrapped: true,
        balanceAda: action.balanceAda,
        balanceTokens: action.balanceTokens,
        balanceUtxos: action.balanceUtxos,
        balanceAddresses: action.balanceAddresses,
      }

    case AccountTypes.Enum.BALANCE_FAILURE:
      return {
        ...state,
        balanceLoading: false,
      }

    default:
      return state
  }
}
