import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { SVGPremium } from "@/svg"
import { Tabs, Tag } from "antd"
import { useAppDispatch, useAppSelector } from "@/redux/provider"

const AdaStakingLayout = ({ children, location }: { children: any; location: any }) => {
  const adaBookmarks = useAppSelector((state) => state.settings.adaBookmarks)
  const [animated, setAnimated] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setAnimated(true)
    }, 200)
  }, [])

  const defaultActiveKey = location.pathname.includes("/ada-staking/pool/") ? "/ada-staking/pool/" : location.pathname

  return (
    <>
      <div className="ray-title mb-3">
        <h1 className="ray-title-head">ADA Staking</h1>
      </div>
      <Tabs
        className="ray-tabs-nomore ray-tabs-links mb-3"
        destroyInactiveTabPane
        activeKey={defaultActiveKey}
        animated={animated}
        items={[
          {
            key: "/ada-staking/",
            label: <Link to="/ada-staking/">Pools List</Link>,
          },
          {
            key: "/ada-staking/premium/",
            label: (
              <Link to="/ada-staking/premium/" className="d-flex align-items-center">
                <span>Promoted</span>
                <span className="ray-premium ms-1">
                  <SVGPremium />
                </span>
              </Link>
            ),
          },
          {
            key: "/ada-staking/bookmarks/",
            label: (
              <Link to="/ada-staking/bookmarks" className="d-flex align-items-center">
                <span>Bookmarks</span>
                {!!adaBookmarks.length && (
                  <Tag color="blue" className="ms-2 me-0">
                    {adaBookmarks.length}
                  </Tag>
                )}
              </Link>
            ),
          },
          {
            key: "/ada-staking/my-position/",
            label: <Link to="/ada-staking/my-position/">My Position</Link>,
          },
        ]}
      />
      {children}
    </>
  )
}

export default AdaStakingLayout
