/**
 * ADA Handle Types
 */

export interface ITokenRaw {
  policyId: string
  assetName: string | null
  assetNameAscii: string | null
  fingerprint: string
}

export interface IAdaHandleCurrent {
  [key: string]: ITokenRaw | null
}
export interface IAdaHandleDisabled {
  [key: string]: boolean
}
export interface IAdaHandleList {
  [key: string]: ITokenRaw[]
}

export interface ISettings {
  current: IAdaHandleCurrent
  disabled: IAdaHandleDisabled
  list: IAdaHandleList
}

/**
 * ADA Handle Actions
 */

export enum Enum {
  CLEAR = "adaHandle/CLEAR",
  ADA_HANDLE_CURRENT_SET = "adaHandle/ADA_HANDLE_CURRENT_SET",
  ADA_HANDLE_DISABLED_SET = "adaHandle/ADA_HANDLE_DISABLED_SET",
  ADA_HANDLE_LIST_SET = "adaHandle/ADA_HANDLE_LIST_SET",
  ADA_HANDLE_UPDATE_SAGA = "adaHandle/ADA_HANDLE_UPDATE_SAGA",
}

export type AClear = {
  type: typeof Enum.CLEAR
}

export type AAdaHandleCurrentSet = {
  type: typeof Enum.ADA_HANDLE_CURRENT_SET
  current: IAdaHandleCurrent
}

export type AAdaHandleDisabledSet = {
  type: typeof Enum.ADA_HANDLE_DISABLED_SET
  disabled: IAdaHandleDisabled
}

export type AAdaHandleListSet = {
  type: typeof Enum.ADA_HANDLE_LIST_SET
  list: IAdaHandleList
}

export type AAdaHandleUpdateSaga = {
  type: typeof Enum.ADA_HANDLE_UPDATE_SAGA
  list: ITokenRaw[]
}

export type Actions = AClear | AAdaHandleCurrentSet | AAdaHandleDisabledSet | AAdaHandleListSet | AAdaHandleUpdateSaga
