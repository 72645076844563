import { CachedTokensTypes } from "./"
import { getPersistSettings, setPersistSetting } from "@/services/localstorage"

const persistStorePrefix = "app.adaHandle."
const initialState: CachedTokensTypes.ISettings = {
  cachedTokens: {},
}

export default (
  state: CachedTokensTypes.ISettings = { ...getPersistSettings(persistStorePrefix, initialState) },
  action: CachedTokensTypes.Actions
): CachedTokensTypes.ISettings => {
  switch (action.type) {
    case CachedTokensTypes.Enum.CLEAR:
      return initialState

    case CachedTokensTypes.Enum.CACHED_TOKENS_ADD:
      return { ...state, cachedTokens: { ...state.cachedTokens, ...action.cachedTokens } }

    case CachedTokensTypes.Enum.CACHED_TOKENS_REMOVE:
      const __cachedTokens = { ...state.cachedTokens }
      action.cachedTokens.forEach((i) => delete __cachedTokens[i])
      return { ...state, cachedTokens: { ...__cachedTokens } }

    default:
      return state
  }
}
